@use "../../../../scss/includes/colors" as color;
@use "../../../../scss/includes/fonts" as font;

.reports-settings {
  height: 100%;
  margin-right: 0%;
  min-height: 280px;
  text-align: left;
  width: 100% !important;

  &__titulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    color: font.$title-color;
    display: block;
    font-size: font.$title-size;
    font-weight: font.$title-weight;
    height: 20px;
  }

  &__subtitulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    color: font.$title-color;
    display: block;
    font-size: font.$subtitle-size;
    font-weight: font.$title-weight;
    height: 27px;
  }

  &__content {
    background-color: color.$grey-background;
    border-radius: 15px !important;
    display: block;
    height: 85%;
    padding: 5%;
    position: relative;

    &__chart {
      height: 100%;
      width: 100%;
    }

    &__chart canvas {
      height: 100% !important;
      width: 100%;
    }
  }

  &__legend {
    display: grid;
    grid-template-columns: auto auto;
    width: 50%;

    &__item {
      color: color.$black-color;
      display: flex;
      flex-direction: row;
      font-weight: bold;
      margin-bottom: 1%;

      &__secondary {
        background-color: color.$secondary-color;
        margin-right: 1%;
        width: 10%;
      }

      &__secondary-light {
        background-color: #b3f4ed;
        margin-right: 1%;
        width: 10%;
      }
    }
  }
}

.charts {
  margin-top: 15%;
}

.chart-container {
  height: 100%;
  width: 100%;
}
