@use "../../../../scss/includes/colors" as color;

.eventscard {
  background-color: color.$grey-background;
  border-radius: 12px;
  height: 100% !important;
  margin-bottom: 0;

  &__title {
    font-weight: bold;
    line-height: 3;
    margin-bottom: 10%;
    margin-left: 4%;
  }

  &__noticon {
    float: right;
    height: 100%;
    margin-right: 3%;
  }

  &__alerts-grid-container {
    display: grid;
    gap: 2%;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(6, 27%) !important;
    height: 90%;
    margin-left: 2%;
    margin-right: 2%;
    overflow-y: scroll;

    &__item {
      color: color.$black-color;
      font-size: 12px;
      font-weight: bold;
      height: 100% !important;
      margin-right: 2%;
    }
  }
}
