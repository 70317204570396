@use "../../../../../scss/includes/colors" as color;
@use "../../../../../scss/includes/fonts" as font;
@use "../../../../../scss/includes/variable" as variable;
@use "../../../../../scss/includes/mixins" as mixins;

.dropbtn {
  color: color.$white-color;
}

.main_menu {
  align-items: center !important;
  background-color: color.$primary-color !important;
  border-radius: 10px;
  display: block;
  height: 5.5% !important;
  margin-top: 8.7%;
  width: 90%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    border-radius: 0;
    margin-right: 10% !important;
    margin-top: 0%;
    min-height: 30px;
  }

  &__grid-container {
    align-items: center;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 100% !important;
    height: 100% !important;
    justify-content: center;
    width: 100% !important;

    &__tooltip {
      color: color.$grey-intermediate;
    }

    &__item {
      align-items: center !important;
      display: flex;
      height: 100% !important;
      justify-content: center;

      &__img {
        height: auto;
        max-width: 100%;
        width: 65%;
      }
    }
  }

  /* &__menu-grid-container > div {
    align-items: center;
    display: flex;
    display: inline-block;
    height: 100%;
    justify-content: center;
    text-align: center;
  } */

  &__launcher {
    align-items: center;

    /* box-shadow: 1px 0 5px #3a3a3a; */
    display: flex;

    /* height: 80px; */
    justify-content: center;

    /* padding: 8px 0 9px 10px; */
    position: relative;
    width: 100%;

    @media (max-width: 660px) {
      width: 100%;
    }

    &__button {
      color: #fff !important;
      cursor: pointer;
      font-size: 28px !important;
    }
  }

  &__links {
    width: calc(100% - 250px);

    .dropbtn {
      color: color.$white-color;
    }

    .navbar-expand {
      background-color: color.$green-color;
      height: 100%;
      padding: 0 !important;

      .container {
        height: 100%;
      }

      .navbar-nav {
        height: 100%;
        margin-left: 20px;
      }

      .nav-link.active {
        border-bottom: 8px solid color.$white-color;
        color: color.$white-color !important;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      .nav-link-right {
        color: color.$white-color;
        display: "inline";
        line-height: 66px;
        margin: 0 10px;
        text-decoration: none;
      }

      .nav-link {
        color: color.$white-color;
        line-height: 66px;
        margin: 0 10px;
        padding: 50px;
      }

      .nav-link:hover {
        background-color: color.$white-color; /* #8079a3; */
        // font-weight: 500;
        // font-size: 18px;
      }
    }

    .list-icons {
      border: 1px solid color.$red-color;
      height: 100%;
      margin-right: 20px;
      position: relative;
    }

    .icons {
      color: color.$white-color;
      cursor: pointer;
      margin: 0 5px;
    }

    .icons:hover {
      color: color.$red-color;
    }

    .settings_submenu {
      background-color: color.$yellow-color;
      border: 1px solid color.$black-color;
      padding: 15px 5px;

      /* position: absolute; */
      right: 10px;
      width: 100%;
    }

    .settings_submenu > ul {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    //  ul li:first-child{
    //    border-top: unset;
    //  }
    //  ul li{
    //    width: 100%;
    //    text-align: center;
    //    border-top: 1px solid color.$secondary-color;
    //    cursor: pointer;
    //    padding: 5px;
    //  }
    ul li:hover {
      background-color: color.$grey-light;
    }
  }

  &__offcanvas {
    background-color: color.$white-color !important;
    display: flex !important;

    &__body {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    &__list__item {
      background-color: color.$white-color !important;
      padding: 0 !important;
    }

    &__list__item-2 {
      background-color: color.$white-color !important;
      color: color.$white-color !important;
      cursor: pointer;
      display: block;
      padding: 10px 20px !important;
      text-decoration: none !important;
    }

    &__list__item__link {
      color: color.$white-color !important;
      display: block !important;
      padding: 10px 20px;
      text-align: left !important;
      text-decoration: none !important;
      width: 100%;
    }
  }
}

.navbar-expand .dropdown:hover .dropbtn {
  background-color: color.$white-color; /* #8079a3; */
  // font-weight: 500;
  // font-size: 18px;
}

.dropdown {
  display: none;
  position: absolute;
}

.dropdown-content {
  background-color: color.$primary-color;
  color: color.$white-color;
  margin-top: 35%;

  /*  display: none; */
  position: absolute;
}

.dropdown-item-link {
  color: color.$white-color;
  text-align: center;
  text-decoration: none !important;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
