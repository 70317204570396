@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/variable" as variable;
@use "../../scss/includes/mixins" as mixins;

.register_page {
  display: flex;
  height: 100%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    display: block;
  }

  &__item {
    align-items: center !important;
    height: 100%;
    justify-content: center !important;
    width: 50% !important;

    @media screen and (max-width: mixins.$mobile-max-width) {
      width: 100% !important;
    }

    &__content {
      display: flex;
      flex-direction: column;
      height: 80%;
      justify-content: center;
      width: 100%;

      &__title {
        align-items: center;
        border-radius: 10px;
        display: flex;
        height: 5% !important;
        margin-left: 20%;

        @media screen and (max-width: mixins.$mobile-max-width) {
          margin-left: 10% !important;
        }

        &__text {
          color: font.$subtitle-color;
          display: inline-block;
          font-size: font.$subtitle-size;
          font-weight: font.$title-weight;
          margin-left: 1%;
        }

        &__img {
          display: inline-block;
          height: 60%;
        }
      }

      &__form {
        background-color: color.$grey-background !important;
        border-radius: 15px;
        height: auto !important;
        margin-bottom: 2%;
        margin-left: 20%;
        width: 60%;

        @media screen and (max-width: mixins.$mobile-max-width) {
          margin-left: 10%;
          width: 80%;
        }

        &__oauth {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: auto;
          justify-content: center;
          margin-bottom: 5%;
          width: 100%;
        }
      }

      &__message {
        color: color.$grey-intermediate;
        font-size: font.$subtitle-size;
        font-weight: font.$subtitle-weight;
        margin: 0 auto;

        &__link {
          color: color.$grey-dark;
          text-decoration: underline;
        }
      }
    }
  }
}

.left-register {
  align-items: center;
  background-color: color.$secondary-color;
  color: color.$secondary-color;
  display: flex;
  justify-content: center;
  position: relative;

  @media screen and (max-width: mixins.$mobile-max-width) {
    background-color: color.$secondary-color;
    padding: 20px;
  }
}

.right-register {
  background-color: color.$white-color !important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (max-width: mixins.$mobile-max-width) {
    width: 100%;
  }
}

._register {
  align-items: center !important;

  /* @include mix.form-separation-top-desktop; */

  border-radius: 3px;
  color: color.$white-color;
  padding: variable.$default-padding-form;
  top: 0;

  &__title {
    background: transparent;
    font-weight: bold;
    margin-bottom: 0;
    padding-top: 20px;
    text-align: center;
    width: 100%;
  }

  &__wrapper {
    padding: 5px;
  }

  &__form {
    label {
      font-size: variable.$default-font-size !important;
    }

    .form-control {
      border-radius: 8px !important;
      color: color.$primary-color;
      padding: 15px !important;
    }
  }
}

.register-logo-inferior {
  bottom: 0;

  /* Agrega otros estilos para el elemento con la clase .logo-inferior */
  color: color.$white-color;
  display: inline-block;
  font-size: 10px;
  margin-bottom: 4%;
  margin-right: 4%;

  /* Agrega el posicionamiento absoluto para situar el elemento en la esquina inferior derecha del Container */
  position: absolute;
  right: 0;
  width: 14%;
}
