@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

/* $skyblue: #f3faff;
$bluelight: #c1dcf1;
$greenlight: #b3f4ed;
$bluedark: #74b9ef;
$greendark: #42e3d4;
$greycolor: #c4c3c5;
$black: #707070;
$whitecolor: #f3f2f5;
$whitecolordark: #f5f5f5;
$rowfilled: #def2f0; */

.table {
  height: 78% !important;
  overflow: auto;
  overflow-y: scroll;
  position: relative;
  width: 100%;

  &__editable {
    cursor: pointer;
    margin-bottom: 20px;
    min-width: 100%;
    table-layout: fixed;
    width: 100%;

    &__thead {
      background-color: color.$grey-intermediate;
      border: 1px solid color.$grey-light;
      color: color.$white-color;
      position: sticky !important;
    }

    &__thead__tr__th {
      font-weight: 500 !important;
      overflow: hidden;
      padding: 10px;
      text-overflow: ellipsis !important;

      &__text {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__btn {
          cursor: pointer;
        }

        &__popover {
          color: color.$grey-dark;
          width: 10vw !important;

          &__item {
            align-items: center;
            display: flex;
            flex-direction: row;

            &__checkbox {
              margin-right: 4%;
            }
          }
        }
      }
    }

    &__thead__tr_x_th__text {
      font-size: font.$subsubtitle-size;
    }

    &__tbody__tr.final_line {
      border-top: 3px solid color.$white-color !important;
    }

    &__tbody__tr {
      background-color: color.$white-color;
      border-bottom: 1px solid color.$grey-light;
    }

    &__tbody__tr__td {
      border: 1px solid color.$grey-light;
      padding: 5px;
      text-overflow: ellipsis !important;

      &.auto_ses_act {
        background-color: #74b9ef;
      }

      &.man_ses_act {
        background-color: color.$secondary-color !important;
      }

      &.auto_ses_ant {
        background-color: #c1dcf1 !important;
      }

      &.man_ses_ant {
        background-color: #b3f4ed !important;
      }
    }

    &__tbody__tr__td__span {
      color: color.$grey-dark;
      font-size: font.$subsubtitle-size;
      font-weight: thin;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.isEditable {
        color: color.$grey-dark;
      }
    }

    &__tbody__tr__td__input {
      border: 0;
      color: color.$grey-dark;
      font-size: font.$subsubtitle-size;
      text-align: center;
      width: 100px !important;
      width: 100% !important;
    }

    &__tbody__tr__td__lst {
      border: 0;
      color: color.$grey-dark;
      font-size: font.$subtitle-size;
      text-align: center;
      width: 100% !important;
    }

    &__tbody__tr__td__icon {
      color: color.$grey-intermediate;
      cursor: pointer;
    }
  }

  &__btn {
    background-color: color.$grey-intermediate;
    border-radius: 15px;
    color: color.$white-color;
    position: absolute;
    width: 20%;
  }
}

.months {
  align-items: center;
  background-color: color.$white-color;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  color: color.$grey-dark;
  display: flex;
  flex-direction: row;
  height: 10%;
  justify-content: space-between;
  margin-bottom: 2%;
  padding: 1.5%;
  position: relative;
  width: 100%;

  &__title {
    font-weight: bold;
    text-decoration: underline;
  }

  &__btn {
    align-items: center;
    border-radius: 25px !important;
    color: color.$grey-light;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
    width: 23%;
  }
}

.table__editable__tbody__tr__td__input:focus {
  outline: 0 solid color.$primary-color !important;
}

.table__editable__tbody__tr__td__lst:focus {
  outline: 0 solid color.$primary-color !important;
}

.table thead {
  position: sticky !important;
  top: 0;
}

.table__column-wide {
  width: 200px; /* Ancho deseado para las columnas anchas */
}

.table__column-narrow {
  width: 100px; /* Ancho deseado para las columnas estrechas */
}

.Patente {
  width: 7%;
}

.editColumn {
  width: 4%;
}

.Peso {
  width: 8%;
}

.Establecimiento {
  width: 13%;
}

.Gestion {
  width: 13%;
}

.Tratamiento {
  width: 13%;
}

.Inmueble {
  width: 13%;
}

.Gestor .Material {
  width: 8%;
}

.Fecha {
  width: 10%;
}

.date-picker-sinader {
  height: 0;
  padding: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}

.date-picker-container {
  left: 0;
  position: absolute;
  top:
    calc(
      100% + 5px
    ); // Coloca el DatePicker justo debajo del botón con 5px de separación

  z-index: 1000; // Asegura que el DatePicker esté encima de otros elementos si es necesario
}
