@use '../../../../../scss/includes/colors' as color;
.MainLoader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9;
}
