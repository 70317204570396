@use "../../../../scss/includes/colors" as color;
@use "../../../../scss/includes/fonts" as font;

.pdf-container {
  height: 100%;
  left: -1000px;
  position: absolute;
  top: 0;
}

.k-pdf-export p {
  color: color.$grey-dark;
  font-size: 10px;
  line-height: 1.25;
  text-align: justify;
  text-justify: center;
}

.k-pdf-export h1 {
  color: color.$grey-dark;
  font-size: 10px;
  font-weight: bold;
  text-align: left !important;
}

.k-pdf-export h2 {
  color: color.$grey-dark;
  font-size: 8px;
}

@font-face {
  font-family: "DejaVu Sans";
  src:
    url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src:
    url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf")
    format("truetype");
}

.k-pdf-export {
  font-family: "DejaVu Sans", sans-serif;
}
