@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;

.details {
  background-color: color.$secondary-color !important;
  color: color.$white-color;
}

.ant-drawer-header {
  height: 0;
}
