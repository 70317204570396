@use "./colors" as color;
@import "https:// fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https:// fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap";

:root {
  /* fonts */
  --font-poppins: "Poppins", sans-serif;
  --font-noto: "Noto Serif", serif;
  --font-roboto: "Roboto", serif;
  --breakpoint: 300px;
}

// Define tus variables de texto
$bigtitle-size: 1.2vw; // 17px
$bigtitle-weight: bold;
$bigtitle-color: color.$black-color;
$title-size: 1vw; // 17px
$title-weight: bold;
$title-color: color.$black-color;
$subtitle-size: 0.9vw; // 17px
$subtitle-weight: normal;
$subtitle-color: color.$grey-dark;
$subsubtitle-size: 0.7vw; // 14px
$subsubtitle-weight: normal;
$subsubtitle-color: color.$grey-dark;
$body-size: 1vw; // 11px
$body-weight: normal;
$button-size: 0.8vw; // 14px
$button-weight: normal;
$button-color: color.$white-color;
