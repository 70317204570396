@use "../../../../scss/includes/colors" as color;
@use "../../../../scss/includes/fonts" as font;
@use "../../../../scss/includes/mixins" as mixins;

.ecoequivalenceitem {
  align-items: center !important;
  background-color: color.$grey-background;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; // O usa "contain" según tus preferencias
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 5%;
  position: relative;
  text-align: center;
  width: 100% !important;

  &__info {
    margin-top: auto;
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__header {
      background-color: rgb(255 255 255 / 48%);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      height: 35%;
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;

      &__value {
        align-items: center;
        background-color: color.$grey-light;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: color.$white-color;
        display: flex; // Utilizar flexbox
        flex-direction: row;
        font-size: 8px;
        height: 85%;
        justify-content: center;
        text-align: center;
        width: 85%;
        margin-top: auto;
      }
    }

    &__content {
      align-items: center;
      background-color: color.$white-color;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      height: 65%;
      justify-content: center;
      padding: 2%;
      text-align: center;
      width: 100%;
  
      &__title {
        color: color.$grey-dark;
        font-size: 7px;
        font-weight: font.$title-weight;
        height: 35%;
        margin-bottom: 2%;
      }
  
      &__detail {
        color: color.$grey-dark;
        font-size: font.$subsubtitle-size;
        font-size: 6px;
        height: 78% !important;
        hyphens: auto !important;
        width: 80%;
  
        /* @media screen and (max-width: mixins.$mobile-max-width) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        } */
  
        &__number {
          font-weight: font.$title-weight;
        }
      }
    }
  }

  &__value-container {
    background-color: rgb(255 255 255 / 48%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 20%;
    width: 55%;
    align-items: center;
    justify-content: center;

    &__value {
      align-items: center;
      background-color: color.$grey-light;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      color: color.$white-color;
      display: flex; // Utilizar flexbox
      flex-direction: row;
      font-size: 8px;
      height: 75%;
      justify-content: center;
      text-align: center;
      transform: translateX(-50%);
      width: 85%;
    }
  }

  &__content {
    align-items: center;
    background-color: color.$white-color;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    height: 35%;
    justify-content: center;
    padding: 2%;
    text-align: center;
    width: 90%;

    &__title {
      color: color.$grey-dark;
      font-size: 7px;
      font-weight: font.$title-weight;
      height: 35%;
      margin-bottom: 2%;
    }

    &__detail {
      color: color.$grey-dark;
      font-size: font.$subsubtitle-size;
      font-size: 6px;
      height: 78% !important;
      hyphens: auto !important;
      width: 80%;

      /* @media screen and (max-width: mixins.$mobile-max-width) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      } */

      &__number {
        font-weight: font.$title-weight;
      }
    }
  }
}

.water {
  background-image: url("../../../../../public/svg-icons/eco-equivalences/water+.svg");

  @media screen and (max-width: mixins.$mobile-max-width) {
    animation: change-background-water 10s ease infinite; /* Cambiar cada 2 segundos (4s / 2 imágenes) */
  }

  &:hover {
    animation: none; /* Detener la animación cuando se realiza hover */
    background-image: url("../../../../../public/svg-icons/eco-equivalences/water.svg");
  }
}

@keyframes change-background-water {
  0%,
  100% {
    background-image: url("../../../../../public/svg-icons/eco-equivalences/water+.svg");
  }

  50% {
    background-image: url("../../../../../public/svg-icons/eco-equivalences/water.svg");
  }
}

.trees {
  background-image: url("../../../../../public/svg-icons/eco-equivalences/trees+.svg");

  @media screen and (max-width: mixins.$mobile-max-width) {
    animation: change-background-trees 10s ease infinite; /* Cambiar cada 2 segundos (4s / 2 imágenes) */
  }

  &:hover {
    animation: none; /* Detener la animación cuando se realiza hover */
    background-image: url("../../../../../public/svg-icons/eco-equivalences/trees.svg");
  }
}

@keyframes change-background-trees {
  0%,
  100% {
    background-image: url("../../../../../public/svg-icons/eco-equivalences/trees+.svg");
  }

  50% {
    background-image: url("../../../../../public/svg-icons/eco-equivalences/trees.svg");
  }
}

.co2 {
  background-image: url("../../../../../public/svg-icons/eco-equivalences/co2+.svg");

  @media screen and (max-width: mixins.$mobile-max-width) {
    animation: change-background-co2 10s ease infinite; /* Cambiar cada 2 segundos (4s / 2 imágenes) */
  }

  &:hover {
    animation: none; /* Detener la animación cuando se realiza hover */
    background-image: url("../../../../../public/svg-icons/eco-equivalences/co2.svg");
  }
}

@keyframes change-background-co2 {
  0%,
  100% {
    background-image: url("../../../../../public/svg-icons/eco-equivalences/co2+.svg");
  }

  50% {
    background-image: url("../../../../../public/svg-icons/eco-equivalences/co2.svg");
  }
}

.energy {
  background-image: url("../../../../../public/svg-icons/eco-equivalences/energy+.svg");

  @media screen and (max-width: mixins.$mobile-max-width) {
    animation: change-background-energy 10s ease infinite; /* Cambiar cada 2 segundos (4s / 2 imágenes) */
  }

  &:hover {
    animation: none; /* Detener la animación cuando se realiza hover */
    background-image: url("../../../../../public/svg-icons/eco-equivalences/energy.svg");
  }
}

@keyframes change-background-energy {
  0%,
  100% {
    background-image: url("../../../../../public/svg-icons/eco-equivalences/energy+.svg");
  }

  50% {
    background-image: url("../../../../../public/svg-icons/eco-equivalences/energy.svg");
  }
}
