@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.header {
  border-bottom: 3px color.$grey-background;
  border-bottom-style: dotted;
  display: flex;
  flex-direction: row;
  height: auto !important;
  margin-bottom: 2%;
  padding-bottom: 2%;

  &__breadcrumb {
    display: block;
    font-weight: bold !important;
    height: 100%;
    width: 90%;

    &__title {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;

      &__imgLogo {
        height: 55% !important;
        margin-right: 8% !important;
      }

      &__text {
        margin-right: 0;
      }
    }
  }

  &__goBack {
    color: color.$grey-light;
    cursor: pointer;
    font-weight: font.$subtitle-weight;
    text-align: right;
    text-decoration: underline;
    width: 10%;
  }
}

li {
  @media screen and (max-width: mixins.$mobile-max-width) {
    display: flex;
    height: 25px !important;
  }
}
