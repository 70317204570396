@use "../../../../scss/includes/colors" as color;
@use "../../../../scss/includes/fonts" as font;
@use "../../../../scss/includes/mixins" as mixins;

.eventInfoModal {
  align-items: center;
  background: rgb(0 0 0 / 50%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__main {
    background-color: color.$white-color;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1%;
    height: auto !important;
    margin: auto;
    padding: 15px 20px;
    position: relative; /* Agrega posicionamiento relativo */
    width: 28%;

    &__menu {
      align-items: center;
      color: color.$grey-light;
      display: flex;
      flex-direction: row;
      height: 20px;
      justify-content: space-between;
      justify-content: flex-end;
      width: 100%;

      &__icon {
        margin-left: 3%;

        &:hover {
          color: color.$grey-intermediate;
          font-weight: bold !important;
        }
      }
    }

    &__header {
      border-bottom: 0.5px solid color.$grey-light;
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 0 0 5%;

      &__title {
        align-items: center;
        color: color.$grey-dark;
        display: flex;
        flex-direction: row;
        font-weight: bold;
        gap: 3%;
        margin-bottom: 1%;
        width: 100%;

        &__circle {
          border-radius: 50%;
          height: 10px;
          width: 10px;
        }
      }

      &__date {
        color: color.$grey-intermediate;
        display: flex;
        flex-direction: row;
        gap: 3%;
        margin-left: 10px;
        width: 100%;
      }
    }

    &__content {
      border-bottom: 0.5px solid color.$grey-light;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: auto;
      padding: 2% 5%;

      &:last-child {
        border: 0;
      }

      &__item {
        color: color.$grey-dark;
        display: flex;
        flex-direction: column;

        &__title {
          align-items: left;
          color: color.$grey-intermediate;
          display: flex;
          margin-bottom: 1%;
        }

        &__detail {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &__imgContainer {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 15%;

            &__img {
              display: flex;
              height: auto;
              width: 100%;
            }
          }

          &__text {
            display: flex;
            flex-direction: column;
            gap: 3%;
            width: 81%;

            &__name {
              align-items: flex-start;
              display: flex;
              font-weight: bold;
            }

            &__info {
              align-items: flex-start;
              color: color.$grey-intermediate;
              display: flex;
              text-align: left;
            }

            &__note {
              color: color.$grey-intermediate;
              font-style: italic;
              text-align: left;
            }

            &__button {
              align-items: center;
              background-color: color.$primary-color;
              border-radius: 15px !important;
              color: font.$button-color;
              cursor: pointer;
              display: flex;
              font-size: font.$button-size;
              height: 20px;
              justify-content: center;
              margin-top: 3% !important;
              text-align: center;
              width: 60%;

              @media screen and (max-width: mixins.$mobile-max-width) {
                font-size: 10px !important;
              }
            }
          }
        }
      }
    }
  }
}

.event_alert_container {
  align-items: center;
  background: rgb(0 0 0 / 50%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &__main {
    background-color: #fff;
    border-radius: 4px;
    display: block;
    display: flex;
    flex-direction: column;
    height: 25% !important;
    justify-content: space-between;
    margin: auto;
    padding: 10px;
    width: 350px;

    &__title {
      align-items: center;
      border-bottom: 0.5px solid color.$grey-light;
      color: color.$grey-dark;
      display: flex;
      flex-direction: row;
      font-weight: bold;
      height: 20px;
      justify-content: space-between;
      padding: 15px 0 20px;
      width: 100%;
    }

    &__title__close {
      color: color.$grey-intermediate;
      cursor: pointer;
      position: absolute;
      right: 0;
    }

    &__text {
      color: font.$subtitle-color;
      display: flex;
      flex-direction: column;
      font-size: font.$subtitle-size;
      gap: 10% !important;
      padding: 5%;
      text-align: center;
    }

    &__btns {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
}
