@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;

.formbuilder {
  color: font.$subtitle-color;
  display: flex;
  font-size: font.$subsubtitle-size;
  height: auto;
  justify-content: center;
  padding: 5% 7%;
  width: 100%;

  &__form {
    color: color.$grey-dark;
    font-size: font.$subtitle-size !important;
    max-width: 400px;
    width: 100%;

    .mb-3 {
      border-radius: 15px;
      font-size: font.$subtitle-size !important;
      margin-bottom: 0.8rem !important;
      position: relative;
    }
  }

  .invalid-feedback {
    color: #dc3545;
    margin-top: 0.25rem;
    width: 100%;
  }

  .noshowelement {
    display: none;
  }

  .form-label {
    background: color.$grey-background;
    font-size: font.$subtitle-size;
    margin-bottom: 0.5rem;
    margin-left: 10px;
    margin-top: -14px;
    padding: 2px 5px;
    position: absolute;
  }

  .form-label.disabled {
    background-color: color.$grey-background;
    font-size: font.$subtitle-size;
  }

  .formbuilder__form .mb-3.disabled::before {
    background-color: color.$grey-background;
    content: " ";
    display: inline-block;
    font-size: font.$subtitle-size;
    height: 1px;
    position: absolute;
    width: 100%;
  }

  .form-control {
    appearance: none;
    background-clip: padding-box;
    background-color: color.$grey-background !important;
    border: 1px solid color.$grey-light;
    border-radius: 10px;
    color: font.$subtitle-color;
    display: block;
    font-size: font.$subtitle-size;
    font-weight: 400;
    height: 41px;
    line-height: 1.5;
    min-width: 100px;
    padding: 0 20px;
    position: relative;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;

    &__input {
      border: 0;
      height: 100%;
      width: 100%;
    }
  }

  .preview {
    border: 0;
    border-bottom: 1px solid color.$white-color;
    border-radius: 0 !important;
    padding: 0 !important;
    pointer-events: none;
  }

  .form-control:focus {
    outline: 0 solid color.$primary-color !important;
  }

  .form-control__input:focus {
    outline: 0;
  }

  .form-control.invalid {
    background-image: url("../../../public/icons/error.svg");
    background-position: right calc(0.375em + 0.1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-color: #dc3545;
    font-size: font.$subtitle-size;
    padding-right: calc(1.5em + 0.75rem);
  }

  .form-control-color {
    font-size: font.$subtitle-size;
    padding: 15px !important;
  }

  .form-radio-buttons {
    appearance: none;
    background-clip: padding-box;
    background-color: color.$grey-background;
    border-radius: 10px;
    color: font.$subtitle-color;
    display: grid;
    font-weight: 400;
    gap: 2%;
    grid-template-columns: repeat(2, 1fr);
    height: 41px;
    line-height: 1.5;
    min-width: 100px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;

    &__item {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;

      &__btn {
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;
      }

      &__name {
        align-items: center;
        border: 1px solid color.$grey-light;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        position: relative;
        width: 100%;
      }
    }
  }

  .form-checkboxes {
    appearance: none;
    background-clip: padding-box;
    background-color: color.$grey-background;
    border-radius: 10px;
    color: font.$subtitle-color;
    display: grid;
    font-weight: 400;
    gap: 2%;
    grid-template-columns: repeat(4, 1fr);
    height: 41px;
    line-height: 1.5;
    min-width: 100px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
  }

  .btn {
    background-color: color.$primary-color;
    border-color: color.$primary-color;
    border-radius: 50px !important;
    font-size: font.$subtitle-size;
    height: 30px !important;
  }

  .enabled {
    background-color: color.$grey-background;
    font-size: font.$subtitle-size;
    font-weight: bold;
  }
}

.ant-btn {
  align-items: center !important;
  text-align: center !important;
}

.form-radio-buttons__item input:checked + .form-radio-buttons__item__name {
  border: 1px solid color.$secondary-color;
}

.eye-password {
  align-items: center;
  background-color: color.$white-color;
  color: color.$grey-light;
  cursor: pointer;
  display: flex;
  height: 90%;
  padding-right: 3%;
  position: absolute;
  right: 0;
  top: 0;
}
