.tblpropertiesunit {
    height: 200px !important;
}
.settings_client {
    color: rgb(31, 31, 31);
    display: flex;
    flex-direction: column;
    height: 85%;
    /* justify-content: space-between; */
    margin: 0 auto !important;
    margin-top: 2%;
    min-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    width: 95%;
}
