@use "../../scss/includes/colors" as color;
@use "../../scss/includes/mixins" as mixins;

.password-recovery {
  display: grid;
  gap: 5%;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  padding: 15% 10%;
  width: 100%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    display: block;
  }

  &__title {
    color: color.$white-color;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    height: 100%;
    width: 100%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      height: 20%;
    }

    &__logo {
      width: 30%;
    }
  }

  &__form {
    align-items: center;
    background-color: color.$grey-background;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    height: auto !important;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      padding-bottom: 5%;
    }

    &__btn {
      align-items: center;
      background-color: color.$primary-color !important;
      display: flex;
      height: 40px;
      justify-content: center;
      width: 75%;
    }
  }
}
