@use "../../../scss/includes/colors" as color;

.garbage-card {
  background-color: color.$white-color;
  display: inline-block;
  font-size: 15px;
  height: 40%;
  margin-left: 2.5%;
  margin-top: 2%;
  width: 95%;

  &__title {
    color: color.$black-color;
    font-size: 14px;
    font-weight: bold;
    margin-top: 1%;
  }

  &__grid-container {
    background-color: color.$grey-background;
    border-radius: 15px;
    display: grid;
    gap: 2%;
    grid-template-columns: 24% 24% 24% 24%;
    height: 70%;
    margin-right: 2%;
    margin-top: 5%;
    overflow: scroll;
    padding: 3% 3% 3% 1%;
    width: 100%;

    &__item {
      border-radius: 15px;
    }
  }
}

.recolection-grid-container > div {
  color: color.$grey-dark;
  display: inline-block;
  font-size: 8px;
}

.titulo-card {
  margin-bottom: 2%;
  margin-left: 3%;
  margin-top: 2%;

  &--der {
    margin-bottom: 1%;
    margin-left: 5%;
    margin-top: 3%;
  }

  &__noticon {
    float: right;
    margin-right: 3%;
  }
}

.btn-garbage {
  margin-left: 5%;
  margin-top: 45%;
  width: 100%;
}
