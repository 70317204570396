@use "../../../../../scss/includes/colors" as color;
@use "../../../../../scss/includes/fonts" as font;

.certificate {
  display: flex;
  flex-direction: column;
  height: 800px;
  width: 600px;

  &__header {
    background-image: url("../../../../../../public/certificates/header.jpg");
    background-size: 600px 75px;
    display: flex;
    flex-direction: column;
    height: 70px !important;
    justify-content: center;
    margin-bottom: 15px;
    text-align: center;
    width: 600px !important;

    &__title {
      color: color.$white-color;
      font-size: 14px;
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    &__name {
      color: color.$white-color;
      font-size: 11px;
      font-weight: bold;
      margin-top: 10px;
    }
  }

  &__header-balance {
    border-bottom: 1px solid color.$grey-background;
    display: flex;
    flex-direction: column;
    height: 100px !important;
    justify-content: center;
    margin-bottom: 25px;
    margin-left: 30px;
    text-align: center;
    width: 555px !important;

    &__info {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      width: 100%;

      &__text {
        text-align: left !important;
        width: 90% !important;
      }

      &__logo {
        width: 10%;

        &__img {
          text-align: right !important;
          width: 100%;
        }
      }
    }

    &__title {
      align-items: center;
      border-bottom: 5px solid color.$secondary-color;
      display: flex;
      flex-direction: row;
      font-size: 11px;
      padding: 10px 0;
      width: 100%;

      &__logo {
        align-items: center;
        border-right: 2px solid color.$grey-light;
        display: flex;
        height: 40px;
        margin-right: 10px;
        padding: 0 10px 0 0;
        width: 20%;

        &__img {
          text-align: right !important;
          width: 100%;
        }
      }

      &__text {
        display: flex;
        flex-direction: column;
        font-size: 11px;
        width: 100%;

        &__cert-name {
          color: color.$grey-dark;
          font-weight: bold;
          margin-right: 1%;
          text-align: left !important;
        }

        &__property-name {
          align-items: left;
          color: color.$grey-dark;
          display: flex;
          font-size: 9px;
          justify-content: left;
          line-height: 1.25;
          margin-top: 1%;
          text-align: justify;
          width: 100%;
        }
      }
    }
  }

  &__section {
    border-bottom: 1px solid color.$grey-background !important;
    margin-bottom: 20px;
    margin-left: 30px;
    width: 555px !important;

    &__title {
      margin-bottom: 20px;
    }

    &__text {
      margin-bottom: 40px;
      text-align: center !important;
      width: 550px;
    }

    &__table {
      height: auto !important;
      padding-bottom: 20px;
    }

    &__chart {
      height: 250px !important;
      top: -50px;
    }

    &__index {
      display: flex;
      flex-direction: row;
      height: 140px !important;
      top: -50px;

      &__text {
        margin-right: 4%;
        text-align: left !important;
        width: 22%;
      }

      &__chart {
        color: color.$grey-dark;
        display: flex;
        flex-direction: column;
        font-size: 9px !important;
        font-weight: bold;
        margin-left: 2%;
        text-align: left;
        width: 35%;

        &__title {
          margin-bottom: 5px;
        }
      }
    }

    &__ecoeq {
      font-size: 11px !important;
      height: 125px !important;
    }
  }

  &__signatures {
    align-items: center !important;
    display: grid !important;
    gap: 60px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center !important;
    margin-bottom: 30px;
    padding: 60px;
    width: 600px;

    &__text {
      border-top: 1px solid color.$grey-intermediate;
      text-align: center !important;
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 620px;
  }

  &__footer-img {
    bottom: 0;
    vertical-align: left;
    width: 620px;
  }
}

.summary-table {
  background-color: color.$grey-background;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  width: 100%;

  &__thead {
    background-color: color.$grey-intermediate;
    color: color.$white-color;

    &__tr__th {
      font-size: 8px;
      padding: 5px;
      width: 20%;
    }
  }

  &__tbody {
    background-color: color.$grey-background;

    &__tr {
      padding: 5px;

      &__td {
        color: color.$grey-dark;
        font-size: 8px;
        padding: 4px 5px;

        &__container {
          align-items: center;
          background-color: color.$white-color;
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          height: 20px;
          justify-content: center;
          text-align: center;

          &__img {
            height: 150%;
            margin-left: -4px;
            margin-top: 2px;
            position: relative;
          }

          &__text {
            flex: 1;
            text-align: center;
          }
        }

        &__container-total {
          background-color: color.$secondary-color;
          border-radius: 8px;
          color: color.$white-color;
          font-weight: bold;
          padding: 5px;
        }
      }
    }
  }
}
