@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.ecoequivalences-card {
  height: 29% !important;
  min-height: 350px;

  @media screen and (max-width: mixins.$mobile-max-width) {
    max-height: 170px;
  }

  &__titulo-card {
    color: font.$title-color;
    font-size: font.$title-size;
    font-weight: font.$title-weight;
    height: 20px;
    text-align: left;
  }

  &__subtitulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    color: font.$title-color;
    display: flex;
    font-size: font.$subtitle-size;
    font-weight: font.$title-weight;
    height: 27px;
    margin-bottom: 2%;
    text-align: left;

    &__subtitle {
      width: 70%;

      &__icon {
        margin-left: 2%;
      }
    }

    &__date {
      align-items: center;
      background-color: color.$grey-background;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 1%;
      text-align: right;
      width: 30%;

      &__select {
        background-color: color.$grey-background;
        border: 0;
        color: color.$black-color;
        width: 100%;
      }
    }
  }

  &__grid-container {
    display: grid;
    gap: 4% 2%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 47%);
    height: 90%;
    overflow-y: scroll;
    text-align: left;
    width: 100%;
  }

  &__line-container {
    display: grid;
    gap: 1%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 30%);
    height: 90%;
    overflow-y: scroll;
    text-align: left;
    width: 100%;
  }
}
