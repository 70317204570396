@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/mixins" as mixins;

.monthly-card {
  display: flex;
  flex-direction: column;
  height: 23%;
  min-height: 300px;
  text-align: left;

  @media screen and (max-width: mixins.$mobile-max-width) {
    height: 55% !important;
  }

  &__titulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    color: color.$black-color !important;
    display: block;
    font-size: font.$title-size;
    font-weight: font.$title-weight;
    height: 20px !important;
  }

  &__subtitulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    color: color.$black-color !important;
    display: block;
    font-size: font.$subtitle-size;
    font-weight: font.$title-weight;
    height: 27px;
    text-align: left;
  }

  &__content {
    background-color: color.$grey-background;
    border-radius: 15px !important;
    display: block;
    height: 85%;
    overflow-y: scroll;
    padding: 5%;

    &__sub-section {
      height: auto !important;
      margin-bottom: 9% !important;

      &__title {
        color: color.$black-color !important;
        font-size: font.$subtitle-size;
        font-weight: 600;
        margin-bottom: 3%;
        padding-bottom: 0;
      }

      &__detalle {
        color: color.$grey-dark;
        font-size: font.$subtitle-size;
        line-height: 2pt;
        margin-top: 2%;
      }

      &__mesesant {
        color: color.$grey-dark;
        float: right;
        font-size: font.$subsubtitle-size;
        margin-right: 5%;
        margin-top: 2%;
        text-align: right;
        text-justify: right;

        &:hover {
          color: color.$secondary-color;
        }
      }

      &__checkboxes-grid-container {
        display: grid;
        gap: 2%;
        grid-auto-rows: 50% 50%;
        grid-template-columns: 60% 38%;
        height: 80%;
        margin: 0 auto;
        width: 95%;

        &__label {
          margin-left: 5%;
          vertical-align: middle;
        }

        &__input {
          position: relative;
        }
      }

      &__checkboxes-grid-container > div {
        border-radius: 15px;
        color: color.$grey-dark;
        display: inline-block;
        font-size: font.$subsubtitle-size;
        height: 100%;
      }
    }
  }
}

.monthly-card-disabled {
  display: flex;
  flex-direction: column;
  height: 40%;
  min-height: 200px;
  opacity: 0.5; /* Opcional: Cambiar la opacidad para indicar que está deshabilitado */
  pointer-events: none;
  text-align: left;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
