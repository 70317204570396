@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.help {
  display: flex;
  flex-direction: column;
  height: 200%;
  margin-left: 2.5%;
  width: 95%;

  &__section {
    height: 25%;
    justify-content: left;
    margin-bottom: 5%;
    text-align: left;
    width: 100%;

    &__title {
      border-bottom: 3px color.$grey-background;
      border-bottom-style: dotted;
      color: color.$black-color;
      font-weight: bold;
      height: 7%;
      margin-bottom: 2%;
    }

    &__grid-container {
      display: grid;
      grid-gap: 8% 3%;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      height: 90%;

      &__item {
        align-items: center;
        background-color: color.$grey-background;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; // O usa "contain" según tus preferencias
        border-radius: 15px;
        box-shadow: 0 3px 6px rgb(196 195 197 / 50%);
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: center;
        width: 100% !important;

        &__title {
          align-items: center;
          background-color: color.$white-color;
          border-radius: 15px;
          bottom: 0%;
          color: color.$grey-intermediate !important;
          display: flex;
          flex-direction: column;
          font-weight: bold !important;
          height: 22%;
          justify-content: center; /* Centrar verticalmente */
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 100%;
        }
      }
    }

    &__FAQ {
      height: 90%;
      overflow-y: auto !important;
    }

    &__text-area {
      color: color.$grey-intermediate;
      margin-bottom: 1%;

      &:active {
        border: 1px solid color.$primary-color;
      }

      &:hover {
        border: 1px solid color.$primary-color;
      }
    }
  }
}
