@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/mixins" as mixins;

.rbc-month-view {
  border: 1px solid color.$white-color !important;
  border-radius: 5px !important;
}

.rbc-month-header {
  align-items: center;
  background-color: color.$primary-color;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  color: color.$white-color;
  display: flex;
  justify-content: center;
  line-height: 2.5;
}

.rbc-event {
  background-color: color.$white-color !important;
  border-radius: 0 !important;
  color: color.$grey-dark !important;
  height: 100%;
  margin-left: 2.5%;
  width: 95%;
}

.rbc-event-content {
  align-items: center;
  display: flex !important;
  height: 100%;
}

.rbc-date-cell {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px !important;
}

.rbc-day-bg {
  background-color: color.$grey-background !important;
  overflow: hidden !important;
  padding: 5px;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid color.$white-color !important;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid color.$white-color !important;
}

.rbc-toolbar {
  font-size: 1vw !important;

  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 13px !important; // 17px
  }
}

.rbc-time-slot {
  align-items: center;
  display: flex;
  font-size: 1vw !important;

  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 13px !important; // 17px
  }
}

.rbc-timeslot-group {
  align-items: center;
  display: flex;
  font-size: 1vw !important;

  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 13px !important; // 17px
  }
}

.rbc-label {
  display: none;
}

.rbc-today {
  background-color: #74b9ef !important;
  opacity: 0.5;
}

.rbc-button-link {
  color: color.$grey-dark;
  font-size: 0.8vw !important;
  font-weight: bold !important;

  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 13px !important; // 17px
  }
}

.week-view .rbc-button-link {
  color: color.$white-color;
}

.rbc-active {
  background-color: color.$grey-background !important;
  box-shadow: none !important;
}

.rbc-show-more {
  border-radius: 4px;
  color: color.$grey-dark !important;
  cursor: pointer;
  font-size: 0.7vw !important; // 14px
  padding: 5px 3px;

  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 8px !important;
  }
}

.rbc-time-content {
  display: none;
}

.rbc-time-header.rbc-overflowing {
  height: 100%;
}

.rbc-time-header {
  height: 100%;
}

.rbc-row.rbc-time-header-cell {
  align-items: center;
  background-color: color.$primary-color;
  border: 1px solid color.$white-color;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  color: color.$white-color !important;
  display: flex;
  flex: 0 0 30px !important; /* Fuerza que cada fila ocupe exactamente 80px */
  height: 30px !important;
  justify-content: center;
  line-height: 2.5;
}

.rbc-time-view {
  border: 0;
}

.rbc-time-view .rbc-row {
  flex: 0 0 60px; /* Fuerza que cada fila ocupe exactamente 80px */
  height: 60px;
}

.rbc-time-view .rbc-allday-cell {
  padding: 5px 0;
}

.rbc-header {
  align-items: center;
  border: 0;
  color: color.$white-color !important;
  display: flex;
  height: 100%;
  justify-content: center;
}

.rbc-header.rbc-today {
  background-color: color.$primary-color !important;
  opacity: 1 !important;
}

.rbc-row-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  overflow-y: auto;
}

.calendar-header {
  align-items: center;
  color: color.$grey-dark;
  display: flex;
  height: 7%;
  justify-content: space-between;
  margin-bottom: 1%;

  &__navigate {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    width: 18%;

    &__month {
      font-weight: bold;
      margin: 0 3%;
    }

    &__btn {
      color: color.$grey-dark;
      cursor: pointer;
      font-weight: bold !important;
    }
  }

  &__categories {
    background-color: color.$grey-background;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    gap: 1%;
    height: 80%;
    overflow-x: scroll;
    width: 60%;

    &__item {
      align-items: center;
      border-radius: 20px;
      color: color.$white-color;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 20%;
    }
  }

  &__view {
    align-items: center;
    background-color: color.$grey-background;
    border-radius: 20px;
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    height: 80%;
    justify-content: space-between;
    width: 20%;

    &__item {
      align-items: center;
      border-right: 1px solid color.$white-color;
      display: flex;
      height: 100%;
      justify-content: center;

      &:first-child {
        border-bottom-left-radius: 20px !important;
        border-top-left-radius: 20px !important;
      }

      &:last-child {
        border-bottom-right-radius: 20px !important;
        border-right: 0;
        border-top-right-radius: 20px !important;
      }
    }
  }
}

.view-item-active {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.event {
  display: flex;
  flex-direction: column;
  height: 80% !important;
  justify-content: space-between;
  width: 100%;

  &__title {
    font-weight: bold;
  }
}

.event-day {
  display: flex;
  flex-direction: row;
  height: 90%;
  justify-content: space-between;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 70%;

    &__title {
      font-weight: bold;
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1.3vw;
    gap: 5%;
    height: 100%;
    justify-content: flex-end;
    margin-right: 1%;
    width: 25%;

    &__bell {
      color: color.$danger-color;
      height: 100%;
    }

    &__check {
      color: color.$glass-color;
      height: 100%;
    }

    &__comment {
      align-items: center;
      color: color.$primary-color;
      cursor: pointer;
      display: flex;
      height: 50%;
      justify-content: center;
      z-index: 10;

      &__img {
        height: 100%;
      }
    }
  }
}
