@use '../../scss/includes/colors' as color;
@use '../../scss/includes/fonts' as font;
@use '../../scss/includes/mixins' as mixins;

.link {
  color: font.$button-color;
}

.download-register-files {
  &--content {
    &--text-button {
      padding: 0;
      width: 15rem;

      &--image {
        font-size: x-large;
        margin: 0 0.7rem 0 0;
      }

      &--text {
        text-decoration: underline;
      }
    }

    &--text-button a {
      width: 100%;
      color: color.$black-light;
      display: flex;
      vertical-align: middle;
      align-items: center;
      margin: 3px 0px;
      font-weight: 500;
    }
  }
}
