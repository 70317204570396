@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.ranking {
  display: flex;
  flex-direction: column;
  height: 86%;
  margin: 0 auto;
  margin-top: 2%;
  min-height: 400px;
  overflow-y: auto;
  position: relative;
  text-align: left;
  width: 95%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    height: auto;
    padding: 1.5%;
  }

  &__myproperty {
    color: color.$black-color;
    height: 4%;
    margin-bottom: 4%;
    min-height: 100px;

    &__title {
      font-weight: bold;
      line-height: 2;
    }

    &__content {
      align-items: center;
      background-color: color.$grey-background;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      height: 80%;
      justify-content: center;
      width: 100%;

      &__number {
        align-items: center;
        border-right: 1px solid color.$white-color;
        display: flex;
        font-weight: bold;
        height: 100%;
        justify-content: center;
        width: 5%;
      }

      &__data {
        display: grid;
        grid-template-columns: 19% 19% 19% 19% 19%;
        width: 95%;

        &__item {
          align-items: center;
          display: flex;
          padding: 1%;

          &__q {
            font-weight: normal !important;
            margin-left: 2%;
          }
        }
      }
    }
  }

  &__list {
    color: color.$black-color;
    height: 25% !important;
    margin-bottom: 5%;
    min-height: 350px;

    &__title {
      font-weight: bold;
      line-height: 2;
    }

    &__content {
      background-color: color.$grey-background;
      border-radius: 15px;
      height: 98%;
      overflow-y: auto;
      padding: 1%;

      &__message {
        align-items: center;
        color: color.$grey-light;
        display: flex;
        font-weight: bold;
        height: 100%;
        justify-content: center;
        width: 100%;
      }

      &__podium {
        color: color.$black-color;
        display: grid;
        gap: 3%;
        grid-template-columns: repeat(3, 1fr);
        height: 70%;
        margin-bottom: 3%;
        min-height: 200px;
        width: 100%;

        &__item {
          align-items: center;
          background-color: color.$grey-background;
          border-radius: 15px;
          box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          display: flex;
          flex-direction: column;
          height: 100%;
          min-height: 200px;
          position: relative;
          width: 100%;

          &--first {
            height: 105%;
            top: -5%;
          }

          &--third {
            height: 95%;
            top: 5%;
          }

          &__header {
            align-items: center;
            background-color: color.$white-color;
            border-radius: 15px;
            display: flex;
            flex-direction: row;
            height: 25%;
            padding: 2%;
            width: 100%;

            &__logo {
              height: 95%;
              width: 20%;
            }

            &__content {
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: center;
              margin-left: 2%;
              width: 78%;

              &__title {
                font-weight: bold;
                line-height: 1.5;
              }
            }
          }

          &__chart {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 55%;
            justify-content: center;
            position: absolute;
            top: 25%;
            width: 70% !important;

            &__doughtnut {
              height: 150% !important;
              width: 100% !important;
            }
          }

          &__detail {
            border-top: 0.5px solid color.$grey-light;
            bottom: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            height: 20%;
            padding: 2%;
            position: absolute;
            width: 100%;
          }
        }
      }

      &__table {
        background-color: color.$white-color;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;

        &__header {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
          display: flex;
          flex-direction: row;
          width: 100% !important;

          &__first-item {
            align-items: center;
            border-right: 3px solid color.$grey-background;
            display: flex;
            flex-direction: row;
            line-height: 1.5;
            padding: 1%;
            width: 5%;
          }

          &__item {
            align-items: center;
            border-right: 3px solid color.$grey-background;
            display: flex;
            flex-direction: row;
            line-height: 1.5;
            padding: 1% 1.5%;
            width: 19%;

            &:last-child {
              border-right: 0;
            }

            &__img {
              align-items: center !important;
              display: flex;
              height: 50% !important;
              margin-right: 2%;
              object-fit: contain;
            }
          }
        }

        &__body {
          display: flex;
          flex-direction: column;
          margin-top: 2%;
          width: 100%;

          &__row {
            border-bottom: 2px solid color.$grey-background;
            display: flex;
            flex-direction: row;
            line-height: 1.5;
            width: 100%;

            &__first-item {
              align-items: center;
              border-right: 3px solid color.$grey-background;
              display: flex;
              flex-direction: row;
              font-weight: bold;
              padding: 1%;
              width: 5%;
            }

            &__item {
              align-items: center;
              border-right: 3px solid color.$grey-background;
              display: flex;
              flex-direction: row;
              padding: 1% 1.5%;
              width: 19%;

              &:last-child {
                border-right: 0;
              }
            }
          }
        }

        &__title {
          font-weight: bold;
          margin-bottom: 2%;
        }

        &__element {
          align-items: center !important;
          display: flex;
          flex-direction: row !important;
          justify-content: center !important;
          line-height: 1.5;
          width: 100%;

          &__number {
            align-items: center;
            border-right: 1px solid color.$white-color;
            display: flex;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            width: 5%;
          }

          &__img {
            align-items: center !important;
            display: flex;
            height: 25%;
            margin-right: 2%;
          }

          &__name {
            font-size: font.$subsubtitle-size;
            font-weight: bold;
            margin-right: 5%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 50%;
          }

          &__percentage {
            align-items: center !important;
            display: flex;
            font-size: font.$subsubtitle-size;
            font-weight: font.$subsubtitle-weight;
            width: 25%;
          }
        }
      }
    }
  }

  &__punished {
    color: color.$black-color;
    height: auto !important;
    max-height: 25%;
    min-height: 200px;

    &__title {
      color: color.$danger-color;
      font-weight: bold;
      line-height: 2;
    }

    &__content {
      background-color: color.$grey-background;
      border: 2px solid color.$danger-color;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      height: 80%;
      overflow: auto;
      padding: 2%;
      width: 100%;

      &__list {
        align-items: center;
        background-color: color.$white-color;
        border-radius: 15px;
        display: flex;
        flex-direction: column;

        &__item {
          border-bottom: 2px solid color.$grey-background;
          display: flex;
          flex-direction: row;
          line-height: 1.5;
          width: 100%;

          /* &:last-child {
            border-right: 0;
          } */

          &__number {
            align-items: center;
            border-right: 3px solid color.$grey-background;
            color: color.$danger-color;
            display: flex;
            font-weight: bold;
            line-height: 1.5;
            padding: 1% 1.5%;
            width: 5%;
          }

          &__name {
            align-items: center;
            display: flex;
            padding: 1% 1.5%;
            width: 19%;
          }

          &__icon {
            align-items: center;
            display: flex;
            padding: 1%;
            width: 5%;
          }

          &__message {
            align-items: center;
            display: flex;
            padding: 1% 1.5%;
          }
        }
      }
    }
  }
}

.ranking-pdf {
  font-size: 8px;
}

.first-certificate {
  border: 2px solid color.$grey-background;
  height: 170px;
}

.second-certificate {
  border: 2px solid color.$grey-background;
  height: 160px;
  top: 10px;
}

.third-certificate {
  border: 2px solid color.$grey-background;
  height: 150px;
  top: 20px;
}

.detail-certificate {
  height: 20px;
}

.percentage-certificate {
  font-size: 12px;
}
