@use "../includes/colors" as color;
@use "../includes/fonts" as font;
@use "../includes/mixins" as mixins;
@import "../includes/mixins";

html,
body,
.root-component {
  background: color.$secondary-color;
  color: color.$primary-color !important;
  font-family: var(--font-roboto);
}

button {
  border-radius: 4px !important;
  color: color.$pink-color;
  margin: 2px !important;
}

.app-d-flex__left {
  @include app-d-flex(flex-start);
}

.app-d-flex__center {
  @include app-d-flex(center);
}

.app-d-flex__right {
  @include app-d-flex(flex-end);
}

.app-d-flex__between {
  @include app-d-flex(space-between);
}

.app-flexd__column {
  @include app-flexd(column);
}

.app-flexd__c_reverse {
  @include app-flexd(column-reverse);
}

.app-flexd__row {
  @include app-flexd(row);
}

.app-flexd__r_reverse {
  @include app-flexd(row-reverse);
}

.app-d-flex__between_column {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.bzero_btn_primary {
  background-color: color.$secondary-color !important;
  border: 1px solid color.$secondary-color;
  border-radius: 15px !important;
  color: font.$button-color !important;
  cursor: pointer !important;
  font-size: font.$button-size !important;
  height: 25px !important;
  text-align: center !important;
}

/* Estilos para ajustar el tamaño de la fuente del contenido dentro del botón */
.bzero_btn_primary span {
  font-size: inherit !important;
  line-height: 1; /* Establecer una altura de línea para controlar el centrado */
}

.bzero_btn_primary_color {
  align-items: center !important;
  background: color.$primary-color !important;
  border: color.$grey-light !important;
  border-radius: 15px !important;
  color: color.$white-color !important;
  text-align: center;
}

.bzero_btn_secondary {
  background: color.$secondary-color !important;
  border: 1px solid color.$secondary-color !important;
  color: color.$white-color !important;
}

.bzero_btn_yellow {
  background: color.$yellow-color !important;
  border: 1px solid color.$yellow-color !important;
  color: color.$white-color !important;
}

.bzero_btn_success {
  background: color.$secondary-color !important;
  border: 1px solid color.$secondary-color !important;
  border-radius: 15px !important;
  color: color.$white-color !important;
  font-size: font.$subtitle-size;
}

.bzero_btn_danger {
  background: color.$grey-light !important;
  border-radius: 15px !important;
  color: font.$button-color !important;
  cursor: pointer !important;
  font-size: font.$button-size !important;
  height: 25px !important;
  text-align: center !important;

  &:hover {
    background-color: color.$danger-color;
  }
}

.bzero_btn_danger span {
  font-size: inherit !important;
  line-height: 1; /* Establecer una altura de línea para controlar el centrado */
}

.bzero_btn_white {
  background: color.$white-color !important;
  border: 1px solid color.$white-color !important;
  border-radius: 15px !important;
  color: color.$primary-color !important;
}

.bzero_btn_ghost {
  background: transparent !important;
  border: 1px solid color.$white-color !important;
  color: color.$white-color !important;
}

.bzero-btn-rounded {
  border-radius: 50px !important;
  height: 46px !important;
}

html,
body,
.root-component,
main {
  height: 100%;
}

.out-off-canvass {
  display: flex;
  height: calc(100% - 80px);
}

.main_content {
  background-color: color.$white-color;
  margin: 0 !important;
  margin-left: 250px !important;
  padding: 0 !important;
  padding-top: 80px !important;
  width: calc(100% - 520px);

  .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;

    background-color: color.$white-color;
    margin: 0 !important;
    margin-left: auto;
    margin-right: auto;
    padding: 0 !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    width: 100%;

    @media (min-width: 1400px) {
      max-width: 1400px !important;
      width: 100% !important;
    }
  }
}

.left-section {
  bottom: 20px !important;
  left: 20px !important;
}

.options_button {
  bottom: 20px;
  display: flex;
  height: 150px;
  position: fixed;
  right: 20px;
  width: 150px;

  @media screen and (max-width: mixins.$mobile-max-width) {
    bottom: -8px;
    right: -8px;
  }

  &__item {
    height: 70px;
    position: relative;
    width: 70px;
    z-index: 100 !important;

    &__button {
      align-items: center;
      border: 1px solid transparent;
      border-radius: 50% !important;
      box-shadow: 0 5px 15px rgb(0 0 0 / 16%);
      color: color.$grey-dark;
      display: flex;
      font-weight: bold;
      height: 100%;
      justify-content: center;
      width: 100%;
      z-index: 100 !important;

      &__img {
        height: 40% !important;
      }
    }

    &__top {
      animation: 0.1s ease 0s normal forwards 1 fadein;
      font-size: 12px;
      height: 70px !important;
      margin-left: 80px;
      position: absolute;
      width: 70px !important;

      @media screen and (max-width: mixins.$mobile-max-width) {
        margin-left: 65px;
      }

      .__button {
        //  background-color: color.$success-color;
        background-color: color.$white-color;

        /* &:hover {
          background-color: color.$secondary-color;
          color: color.$white-color;
          cursor: pointer;
        } */
      }
    }

    &__left {
      animation: 0.1s ease 0s normal forwards 1 fadein;
      font-size: 14px;
      height: 70px !important;
      margin-top: 80px;
      width: 70px !important;

      @media screen and (max-width: mixins.$mobile-max-width) {
        margin-top: 65px;
      }

      .__button {
        background-color: color.$grey-dark;
      }
    }

    &__main {
      bottom: 0;
      font-size: 3vw;
      margin-left: 50px;
      margin-top: 140px;
      position: absolute;
      right: 0;

      .__button {
        background-color: color.$secondary-color;
        z-index: 100 !important;
      }
    }

    &__main-left {
      bottom: 0;
      font-size: 3vw;
      left: 0;
      margin-right: 50px;
      margin-top: 140px;
      position: absolute;

      @media screen and (max-width: mixins.$mobile-max-width) {
        bottom: -8px !important;
        left: -8px !important;
      }

      .__button-left {
        background-color: color.$primary-color;
      }
    }
  }
}

.user-name {
  animation: 0.1s ease 0s normal forwards 1 fadein;
  color: color.$secondary-color;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  text-align: center;
}

.ta-center {
  text-align: center !important;
}

@keyframes fadein {
  0% {
    opacity: 0;
    width: 0;
  }

  66% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    width: 100px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    width: 0;
  }

  66% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    width: 100px;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    width: 100px;
  }

  66% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    width: 100px;
  }

  66% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

.pbi-wrapper {
  height: 260vh;
  overflow: hidden;
  padding-top: 30px;
  position: relative;
  width: 100%;
}

.PowerbiContainer {
  height: 480vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  > iframe {
    margin-top: -41px !important;
  }
}

.main_content .nav-tabs {
  border-bottom: unset !important;
}

.main_content .nav-link {
  border: unset;
  border-bottom: 1px solid color.$secondary-color !important;
  border-left: unset;
  border-right: unset;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  color: color.$grey-dark !important;
}

.main_content .nav-item.show .nav-link,
.main_content .nav-link.active {
  border-bottom: unset !important;
  border-color: color.$secondary-color !important;
  border-top: 1px solid color.$secondary-color !important;
  color: color.$secondary-color !important;
}

.list-group-item {
  font-size: 14px;
  padding: 4px !important;
}

.list-group-item .lg-name {
  width: 200px;
}

.list-group-item > div {
  width: 100%;
}

.list-group-item > div > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.list-group-item:hover {
  background-color: color.$secondary-color !important;
  cursor: pointer;
}

.list-group-item button {
  height: 17px;
  line-height: 0;
  padding: 0;
  width: 17px;
}

hr {
  border: 0;
  border-top: 1px solid;
  color: inherit;
  margin: 1rem 0;
  opacity: 0.25;
}

.ant-btn {
  align-items: center !important;
  text-align: center !important;
}

.ant-btn-default {
  align-items: center !important;
  text-align: center !important;
}

.bigbigtitle {
  $bigtitle-weight: bold;

  font-size: 1.8vw !important; // 17px

  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 17px !important; // 17px
  }
}

.bigtitle {
  $bigtitle-size: 1.2vw !important; // 17px
  $bigtitle-weight: bold;

  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 15px !important; // 17px
  }
}

.title {
  font-size: 1vw !important; // 17px

  /* font-weight: bold; */

  /* color: color.$black-color; */
  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 13px !important; // 17px
  }
}

.subtitle {
  font-size: 0.9vw !important; // 17px

  /* color: color.$grey-dark; */
  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 11px !important;
  }
}

.subsubtitle {
  font-size: 0.7vw !important; // 14px

  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 8px !important;
  }
}

.popUp {
  background: rgb(0 0 0 / 50%);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &__main {
    background-color: #fff;
    border-radius: 4px;
    display: block;
    height: 250px !important;
    margin: auto;
    margin-top: 200px;
    padding: 15px;
    position: relative; /* Agrega posicionamiento relativo */
    width: 400px;

    &__container {
      height: 100px;
    }

    &__title {
      color: font.$title-color;
      font-size: font.$title-size;
      font-weight: font.$title-weight;
      margin-bottom: 3px;
      position: relative;
    }

    &__title__close {
      color: color.$grey-intermediate;
      cursor: pointer;
      position: absolute;
      right: 0;
    }

    &__text {
      color: font.$subtitle-color;
      display: flex;
      flex-direction: column;
      font-size: font.$subtitle-size;
      margin-top: 5%;
      text-align: left;

      &__upload {
        align-items: center;
        background-color: color.$grey-background;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        line-height: 2;
        margin-bottom: 5%;
        width: 50%;

        &:hover {
          background-color: color.$secondary-color;
        }

        &__label {
          margin-left: 4%;
        }
      }

      &__fileName {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 5%;

        &__name {
          font-weight: normal !important;
        }
      }

      &__template {
        color: color.$grey-light;
        cursor: pointer;
        margin-top: 15%;
        text-align: left;
        text-decoration: underline;
      }

      &__radio-group {
        margin-top: 5% !important;
      }

      &__date-picker {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-left: auto;
        width: 100%;

        &__title {
          font-weight: bold;
          width: 65%;
        }
      }

      &__label {
        color: color.$grey-dark;
      }

      &__detail {
        color: color.$grey-intermediate;
        margin-bottom: 5%;
      }

      &__fieldset {
        margin-bottom: 6%;
        width: 100%;

        &__title {
          font-weight: bold;
          margin-bottom: 2%;
        }

        &__options {
          display: flex;
          flex-direction: row;

          &__item {
            flex: 1;

            &__radio {
              margin-right: 3%;
            }

            &__detail {
              color: color.$grey-light;
              margin-left: 10% !important;
              margin-right: 20% !important;
              margin-top: 3%;
            }
          }
        }
      }

      &__item {
        margin-bottom: 3%;
        width: 100%;

        &__value {
          font-weight: bold;
        }
      }
    }

    &__buttons {
      bottom: 0;
      left: 0;
      padding: 15px; /* Añade espacio alrededor de los botones si es necesario */

      /* Utiliza posicionamiento absoluto para situar los botones en la parte inferior */
      position: absolute;
      right: 0;
      text-align: center;
    }
  }
}
