@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.button {
  align-items: center;
  border-radius: 15px !important;
  color: font.$button-color;
  cursor: pointer;
  display: flex;
  font-size: font.$button-size;
  height: 25px;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: mixins.$mobile-max-width) {
    font-size: 10px !important;
  }

  &:hover {
    background-color: color.$secondary-color;
  }
}

.button-body {
  &--sinader {
    background-color: color.$grey-intermediate;
    padding-top: 0;
    width: 98%;

    &:hover {
      background-color: color.$secondary-color;
    }

    &:disabled {
      background-color: color.$grey-intermediate;
    }
  }

  &--help {
    background-color: color.$grey-intermediate;
    display: block;
    margin-left: auto !important;
    padding-top: 0;
    width: 15%;

    &:hover {
      background-color: color.$secondary-color;
    }

    &:disabled {
      background-color: color.$grey-light;
    }
  }

  &--certificates {
    background-color: color.$grey-intermediate;
    float: right;
    height: 25px;
    width: 50%;

    &:hover {
      background-color: color.$secondary-color;
    }

    &:disabled {
      background-color: color.$grey-light;
    }
  }

  &--garbage-control {
    background-color: color.$danger-color;
    height: 25px;
    width: 35%;
  }

  &--rellenar {
    background-color: color.$danger-color;
    height: 25px;
    width: 90% !important;
  }

  &--edit-profile {
    background-color: color.$grey-intermediate;
    bottom: -20px;
    height: 25px;
    left: 88%;
    position: absolute;
    transform: translateX(-50%);
    width: 24% !important;

    @media screen and (max-width: mixins.$mobile-max-width) {
      left: 22%;
      width: 45% !important;
    }
  }

  &--change-pass {
    background-color: color.$grey-intermediate;
    bottom: -20px; /* distancia desde el borde inferior */
    height: 25px;
    left: 61%; /* 34%; */
    position: absolute;
    transform: translateX(-50%);
    width: 24% !important;

    @media screen and (max-width: mixins.$mobile-max-width) {
      left: 80%; /* 34%; */
      width: 45% !important;
    }
  }

  &--language {
    background-color: color.$grey-intermediate;
    bottom: -20px; /* distancia desde el borde inferior */
    left: 61%;
    position: absolute;
    transform: translateX(-50%);
    width: 24% !important;
  }

  &--evolution {
    align-items: center;
    background-color: color.$grey-intermediate;
    bottom: -15px; /* distancia desde el borde inferior */
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: center;
    left: 80%;
    position: absolute;
    transform: translateX(-50%);
    width: 30% !important;

    &:hover {
      background-color: color.$secondary-color;
    }

    @media screen and (max-width: mixins.$mobile-max-width) {
      width: 35% !important;
    }

    &__content {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 80%;

      &__text {
        flex-grow: 1;
        height: 100%;
        text-align: center;
      }

      &__img {
        height: 80%;
      }
    }
  }

  &--ranking {
    align-items: center;
    background-color: color.$grey-intermediate;
    bottom: -15px; /* distancia desde el borde inferior */
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: center;
    left: 73%;
    position: absolute;
    transform: translateX(-50%);
    width: 40% !important;
    z-index: 2;

    &:hover {
      background-color: color.$secondary-color;
    }

    @media screen and (max-width: mixins.$mobile-max-width) {
      width: 35% !important;
    }

    &__content {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 80%;

      &__text {
        flex-grow: 1;
        height: 100%;
        text-align: center;
      }

      &__img {
        height: 80%;
      }
    }
  }

  &--onboarding {
    align-items: center;
    background-color: color.$primary-color;
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 20% !important;

    &:hover {
      background-color: color.$secondary-color;
    }

    @media screen and (max-width: mixins.$mobile-max-width) {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      width: 90% !important;
    }

    &__content {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 80%;

      &__text {
        flex-grow: 1;
        height: 100%;
        text-align: center;
      }

      &__img {
        height: 80%;
      }
    }
  }

  &--onboardingNext {
    align-items: center;
    background-color: color.$primary-color;
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 5% !important;

    &:hover {
      background-color: color.$secondary-color;
    }

    @media screen and (max-width: mixins.$mobile-max-width) {
      bottom: -80%;
      width: 15% !important;
    }

    &__content {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 80%;

      &__img {
        height: 80%;
      }
    }
  }

  &--onboardingPrev {
    align-items: center;
    background-color: color.$primary-color;
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: center;
    position: absolute;
    width: 5% !important;

    &:hover {
      background-color: color.$secondary-color;
    }

    @media screen and (max-width: mixins.$mobile-max-width) {
      bottom: -80%;
      width: 15% !important;
    }

    &__content {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 80%;

      &__img {
        height: 80%;
        transform: rotate(180deg);
      }
    }
  }

  &--download {
    background-color: color.$grey-intermediate;
    float: right;
    margin-right: 1% !important;
    position: relative;
    width: 100% !important;
  }

  &--download-ranking {
    background-color: color.$grey-intermediate;
    margin-left: 80% !important;
    margin-top: 1%;
    min-height: 25px;
    width: 20% !important;

    @media screen and (max-width: mixins.$mobile-max-width) {
      width: 48% !important;
    }
  }

  &--left-section {
    background-color: color.$grey-intermediate;
    position: relative;
    width: 100% !important;
  }

  &--viewevol {
    background-color: color.$grey-intermediate;
    float: right;
    margin-right: 1% !important;
    margin-top: 3% !important;
    position: relative;
    width: 20% !important;
  }

  &--add {
    background-color: color.$secondary-color;
    bottom: -10px; /* distancia desde el borde inferior */
    left: 75%;
    position: absolute;
    transform: translateX(-1%);
    width: 15% !important;
  }

  &--reposition {
    background-color: color.$danger-color;
    bottom: -10px; /* distancia desde el borde inferior */
    position: absolute;
    transform: translateX(-1%);
    width: 100% !important;
  }

  &--upload-image {
    align-items: center;
    background-color: color.$grey-intermediate;
    display: flex;
    justify-content: center;
    margin-left: auto !important;
    margin-right: 5% !important;
    width: 30% !important;
  }

  &--upload {
    align-items: center;
    background-color: color.$grey-intermediate;
    display: flex;
    justify-content: center;
    width: 100% !important;
  }

  &--create {
    background-color: color.$secondary-color;
    float: left;
    width: 50% !important;
  }

  &--cancel {
    background-color: color.$grey-intermediate;
    float: left;
    width: 50% !important;

    &:hover {
      background-color: color.$danger-color;
    }
  }

  &--page404 {
    background-color: color.$primary-color;
    float: left;
    width: 100% !important;
  }

  &--delete-event {
    background-color: color.$secondary-color;
    width: 100% !important;
  }

  &--autocomplete {
    background-color: color.$grey-intermediate;
    border: 2px solid color.$primary-color;
    float: right;
    margin-left: 1% !important;
    position: relative;
    width: 22%;
  }

  &--save-sinader {
    background-color: color.$grey-intermediate;
    border: 2px solid color.$secondary-color;
    float: right;
    margin-left: 1% !important;
    position: relative;
    width: 22%;
  }

  &--close-month {
    background-color: color.$grey-intermediate;
    float: right;
    margin-left: 1% !important;
    position: relative;
    width: 22%;

    &:hover {
      background-color: color.$secondary-color;
    }
  }

  &--save-configReport {
    background-color: color.$grey-intermediate;
    border: 2px solid color.$secondary-color;
    float: right;
    margin-bottom: 2% !important;
    margin-right: 1% !important;
    position: relative;
    width: 22%;
  }

  &--save-management-cost {
    background-color: color.$secondary-color;
    border: 2px solid color.$secondary-color;
    float: right;
    margin-left: 1% !important;
    position: relative;
    width: 22%;
  }

  &--save-consumption {
    background-color: color.$secondary-color;
    border: 2px solid color.$secondary-color;
    margin-left: 1% !important;
    position: relative;
    width: 45%;
  }

  &--cancel-consumption {
    background-color: color.$grey-intermediate;
    border: 2px solid color.$grey-light;
    margin-left: 1% !important;
    position: relative;
    width: 45%;

    &:hover {
      background-color: color.$danger-color;
    }
  }

  &--download-garbage-control {
    background-color: color.$grey-intermediate;
    position: relative;
    width: 22%;

    &:hover {
      background-color: color.$secondary-color;
    }
  }

  &--register-download {
    background-color: color.$grey-intermediate;
    margin-bottom: 10rem;
    padding-top: 0;
    width: 98%;

    &:hover {
      background-color: color.$secondary-color;
    }

    &:disabled {
      background-color: color.$primary-color;
    }
  }
}

.link {
  color: font.$button-color;
}
