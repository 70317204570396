/* eslint-disable react/prop-types */
@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;

.tablecontainer {
  background-color: color.$grey-background;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 80% !important;
  margin-bottom: 5%;
  overflow-y: auto;
  position: relative;
  width: 100%;

  &__filters {
    background-color: color.$grey-background;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__table-wrapper {
    height: 99%;
    overflow: auto;
    padding-top: 1%;
  }

  &__tblcomponent {
    border: 0.5px solid color.$grey-light;
    cursor: pointer;
    min-width: 100%;
    table-layout: fixed;
    width: 100%;

    &__thead {
      background-color: color.$grey-light;
      color: color.$grey-dark;
      font-weight: bold;
      position: sticky !important;

      &__tr {
        &__th {
          border: unset;
          padding: 5px;
          position: relative;
          text-align: left;

          &.bordered {
            border: 0.5px solid color.$grey-dark;
          }

          &__icon {
            position: absolute;
            right: 10px;
            top: 5px;

            &__btn {
              background: transparent;
              border: unset;
              cursor: pointer;

              &::after {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 10px solid color.$black-color;
                content: "";
                display: inline-block;
                height: 0;
                margin-left: 5px;
                width: 0;
              }
            }

            &__btn.arrow-up {
              &::after {
                rotate: 0deg;
              }
            }

            &__btn.arrow-down {
              &::after {
                rotate: 180deg;
              }
            }
          }
        }
      }
    }

    &__tbody {
      &__tr {
        background-color: color.$white-color !important;
        border-bottom: 0.3px solid color.$grey-light;
        color: color.$grey-dark;
        cursor: pointer;
        overflow-y: scroll;
        text-align: left;

        &__td {
          border: unset;
          padding: 5px;

          &.bordered {
            border: 0.5px solid color.$grey-dark;
          }

          &__icon {
            color: color.$grey-light;

            &:hover {
              color: color.$secondary-color;
            }
          }

          &__icon_dynamic {
            color: color.$grey-light;
            position: relative;
            top: 8px;

            &:hover {
              color: color.$grey-dark;
            }

            > svg {
              margin-top: -15px;
            }
          }
        }

        &:hover {
          background-color: color.$secondary-color !important;
          color: color.$white-color;
        }

        /*  &:hover {
        background-color: color.$secondary-color !important;
      } */
      }
    }
  }

  &__pagination {
    display: flex;
    height: 15%;
    justify-content: space-between;

    &__list {
      align-items: center;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 2px 10px;

      &__nav {
        color: color.$secondary-color;
        font-size: font.$subtitle-size;
        font-weight: font.$subtitle-weight;
        line-height: 38px;

        &__item {
          cursor: pointer;

          &__link {
            border: unset;
            color: color.$grey-dark;
            font-weight: 700;
            margin: 0 15px;
            min-height: 38px;

            &.isActive {
              color: color.$secondary-color;
            }
          }

          &__button {
            background-color: transparent;
            border: unset;
            cursor: pointer;
            font-size: 16px;
            font-weight: 700;
            margin: 0 15px;
            padding: 2px 7px;
          }
        }
      }

      &__regsxpage {
        color: color.$secondary-color;
        margin: 0;
        margin-right: 10px;
      }
    }
  }
}
