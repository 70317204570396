@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.settingsBar {
  background-color: color.$primary-color;
  border-radius: 10px;
  color: color.$white-color;
  display: flex;
  height: 100%;
  min-height: 35px;
  text-align: center;
  width: 100%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    border-radius: 18px;
    margin-right: 10% !important;
    margin-top: 0%;
    min-height: 30px;
  }

  &__setTitle {
    align-items: center;
    background-color: color.$white-color;
    border: 1px solid color.$primary-color;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    color: color.$primary-color;
    display: flex;
    height: 100% !important;
    width: 30%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      border: 0;
      border-radius: 18px;
      width: 100%;
    }

    &__text {
      font-size: font.$title-size;
      font-weight: bold;
      margin-left: 3%;
    }

    &__icon {
      height: 50%;
      margin-left: 10%;
      max-width: 100%;
      width: auto; // Añade esta línea para que el ancho se ajuste automáticamente
    }
  }

  &__grid-container {
    display: grid;
    height: 100% !important;
    width: 70%;

    &__tooltip {
      color: color.$grey-intermediate;
    }

    &__setItem {
      align-items: center;
      background-color: color.$primary-color;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      height: 100% !important;
      justify-content: center;

      &__setIcon {
        height: 60%;
        margin: 0;
        max-height: 30px;
        max-width: 100%;
        padding: 0;
        width: auto;
      }
    }

    &__setItemActive {
      align-items: center;
      background-color: color.$white-color;
      border-left: 5px solid color.$primary-color;
      border-radius: 10px 10px 0 0;
      border-right: 5px solid color.$primary-color;
      border-top: 5px solid color.$primary-color;
      display: flex;
      height: 100% !important;
      justify-content: center;

      &__setIcon {
        height: 60%;
        margin: 0;
        max-height: 30px;
        max-width: 100%;
        padding: 0;
        width: auto;

        /* margin-top: 4%; */
      }
    }
  }
}

.link-bar {
  align-items: center;
  color: color.$primary-color;
  display: flex;
  height: 100% !important;
  width: 100%;
}
