@use "../../../../scss/includes/colors" as color;
@use "../../../../scss/includes/fonts" as font;
@use "../../../../scss/includes/mixins" as mixins;

.subnavbar {
  align-items: center;
  background-color: color.$secondary-color;
  border-radius: 10px;
  color: color.$white-color !important;
  display: flex;
  height: 5.5%;
  margin: 0 auto;
  margin-bottom: 3%;
  margin-top: 3%;
  min-height: 35px;
  position: relative;
  text-align: center;
  width: 95%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    border-radius: 0;
    height: 8% !important;
    margin-top: 0%;
    width: 100%;
  }

  &__content {
    align-items: center;
    background-color: color.$secondary-color;
    color: color.$white-color !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 35px;
    margin: 0 auto;
    text-align: center;
    width: 95%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      border: 1px solid color.$white-color;
      border-radius: 18px;
      grid-template-columns: 45% 45% 10%;
    }

    &__tooltip {
      color: color.$grey-intermediate !important;
    }

    &__item {
      align-items: center !important;
      color: color.$white-color !important;
      display: flex;
      height: 100% !important;
      justify-content: center;
      text-align: center;

      &__link {
        color: color.$white-color !important;
        font-size: font.$bigtitle-size;
        font-weight: thin;
        text-decoration: none;

        &:hover {
          color: color.$white-color;
        }

        &:hover:not(.active) {
          color: color.$white-color !important;
        }

        /* @media screen and (max-width: 400px) {
          font-size: font.$mobile-title-size;
        } */
      }

      &__img {
        height: 35px;
        left: 2px;
        margin: 0;
        max-width: 100%;
        position: relative;
        top: -2.5px;
      }
    }
  }

  &__menu {
    align-items: center;
    background-color: color.$primary-color;
    border-radius: 18px;
    color: color.$white-color !important;
    display: flex;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    width: 100%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      border: 1px solid color.$white-color;
      height: 35px;
      width: 95%;
    }
  }
}

.menu-img {
  display: flex;
  height: 37px !important;
  margin: 0;
  max-width: 100%;
  position: absolute;
  right: 4px;
  top: 7px;
  width: 37px !important;
  z-index: 50;

  &__logo {
    height: 100%;
  }
}

.active {
  color: color.$white-color;
  font-weight: 900;
}

.logo {
  display: inline-block;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.sett {
  background-color: color.$white-color;
}
