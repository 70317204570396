@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.carousel {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px !important;
  height: 680px;
  padding: 0 7px;
}

.carousel > div {
  margin-bottom: 30px; /* Espacio inferior entre componentes internos */
}

.body {
  color: color.$black-color;
  display: flex;
  flex-direction: row;
  gap: 4%;
  height: 86%;
  margin: 0 auto;
  margin-top: 2%;
  min-height: 400px;
  overflow-y: auto;
  width: 95%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    display: flex;
    flex-direction: column;
    gap: 5% !important;
    height: 86%;
    max-width: 360px !important;
    padding: 1.5%;
  }

  &__column-left {
    color: font.$title-color;
    display: flex;
    flex-basis: 59%;
    flex-direction: column;
    gap: 3% !important;
    height: 200%;
  }

  &__column-right {
    color: font.$title-color;
    display: flex;
    flex-basis: 39%;
    flex-direction: column;
    gap: 3%;
    height: 200%;
  }

  &__scroll {
    align-items: center;
    bottom: 20px;
    display: flex;
    height: 15px;
    justify-content: center;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
    width: 100px;

    &__btn {
      background-color: color.$grey-intermediate;
      border-radius: 50% !important;
      height: 15px;
      width: 15px;
    }
  }
}

.ant-carousel .slick-dots {
  margin-top: 20px;
  position: static;
}

.onboarding {
  align-items: center; /* Centra verticalmente */
  background: rgb(0 0 0 / 50%);
  display: flex; /* Utiliza flexbox para centrar vertical y horizontalmente */
  height: 100%;
  justify-content: center; /* Centra horizontalmente */
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__main {
    background-color: color.$white-color;
    border-radius: 20px;
    height: 70% !important;
    margin: auto;
    overflow: scroll;
    padding: 2%;
    position: relative;
    width: 70%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      height: 90% !important;
      width: 90%;
    }

    &__background {
      background-color: color.$white-color;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      width: 100%;

      &__btns {
        bottom: 3%;
        display: flex;
        flex-direction: column;
        height: 10%;
        min-height: 60px;
        position: absolute;
        right: 2%;
        width: 96%;

        @media screen and (max-width: mixins.$mobile-max-width) {
          bottom: 11%;
          height: 7%;
        }

        &__omit {
          color: color.$grey-intermediate;
          margin-left: auto;
          text-align: right;
          text-decoration: underline;

          @media screen and (max-width: mixins.$mobile-max-width) {
            left: 90%;
            transform: translateX(-8%);
          }
        }
      }

      &__content {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 200%;
        margin-top: 10% !important;
        overflow: scroll;
        position: relative;

        &__btns {
          bottom: 7.5%;
          display: flex;
          flex-direction: column;
          height: 10%;
          position: absolute;
          right: 2%;
          width: 96%;

          @media screen and (max-width: mixins.$mobile-max-width) {
            height: 7%;
          }
        }
      }
    }

    &__img {
      height: 100%;
      width: 100%;
    }

    &__container {
      height: 100px;
    }

    &__title {
      color: font.$title-color;
      font-size: font.$title-size;
      font-weight: font.$title-weight;
      margin-bottom: 3px;
      position: relative;
    }

    &__title__close {
      color: color.$grey-intermediate;
      cursor: pointer;
      position: absolute;
      right: 0;
    }

    &__text {
      color: font.$subtitle-color;
      display: flex;
      flex-direction: column;
      font-size: font.$subtitle-size;
      margin-top: 5%;
      text-align: left;

      &__label {
        color: color.$grey-dark;
      }

      &__detail {
        color: color.$grey-intermediate;
        margin-bottom: 5%;
      }

      &__fieldset {
        margin-bottom: 6%;
        width: 100%;

        &__title {
          font-weight: bold;
          margin-bottom: 2%;
        }

        &__options {
          display: flex;
          flex-direction: row;

          &__item {
            flex: 1;

            &__radio {
              margin-right: 3%;
            }
          }
        }
      }

      &__upload {
        background-color: color.$grey-background;
        border-radius: 15px;
      }
    }

    &__buttons {
      bottom: 0;
      left: 0;
      padding: 15px; /* Añade espacio alrededor de los botones si es necesario */

      /* Utiliza posicionamiento absoluto para situar los botones en la parte inferior */
      position: absolute;
      right: 0;
      text-align: center;
    }
  }
}

.zero {
  background-image: url("../../../public/onboarding/onboarding1.png");

  @media screen and (max-width: mixins.$mobile-max-width) {
    background-image: url("../../../public/onboarding/onboarding1mobile.png");
  }
}

.first {
  background-image: url("../../../public/onboarding/onboarding2.png");

  @media screen and (max-width: mixins.$mobile-max-width) {
    background-image: url("../../../public/onboarding/onboarding2mobile.png");
  }
}

.second {
  background-image: url("../../../public/onboarding/onboarding3.png");
  background-size: contain;

  @media screen and (max-width: mixins.$mobile-max-width) {
    background-image: url("../../../public/onboarding/onboarding3mobile.png");
  }
}

.third {
  background-image: url("../../../public/onboarding/onboarding4.png");

  @media screen and (max-width: mixins.$mobile-max-width) {
    background-image: url("../../../public/onboarding/onboarding4mobile.png");
  }
}

/*
.ant-carousel .slick-dots li button {
  background-color: color.$grey-light;
  border-radius: 50% !important;
  height: 10px;
  overflow: hidden;
  width: 10px;
}


.ant-carousel .slick-dots li.slick-active button {
  background-color: color.$grey-intermediate;
  border-radius: 50% !important;
  height: 10px;
  overflow: hidden;
  width: 10px;
}
 */

/*
.ant-carousel {
  .slick-dots {
    position: static;
    margin-top: 20px;

    li button {
      background-color: color.$grey-light;
      border-radius: 50% !important;
      height: 10px;
      overflow: hidden;
      width: 10px;
    }

    li.slick-active button{
      background-color: color.$grey-intermediate;
      border-radius: 50% !important;
      height: 10px;
      overflow: hidden;
      width: 10px;
    }
  }
}
 */
