@use '../../../scss/includes/colors' as color;

.settings-tree {
    background-color: color.$white-color;
    width: 350px;
    input {
        position: absolute;
        top: 3px;
        right: 8px;
    }
    button {
        width: 100%;
        display: flex;
        cursor: pointer;
        border: none;
        padding-left: 15px;
    }
    .level1 {
        width: 320px;
        border-bottom: none;
        margin: 5px 0;
        border-left: 1px solid color.$grey-dark;
        background-color: color.$grey-background;
        position: relative;
        ul {
            margin-left: 20px;
            .level2 {
                border-left: 1px solid color.$primary-color;
                position: relative;
                background-color: color.$grey-light;
                ul.ul_lvl3 {
                    margin-left: 20px;
                    .level3 {
                        border-left: 1px solid color.$textile-color;
                        position: relative;
                        background-color: color.$wood-color;
                    }
                }
            }
        }
    }
    .level1.open > button,
    .level2.open > button {
        background-image: url('./assets/remove.png');
        background-repeat: no-repeat;
        background-size: 15px;
        background-position-y: top;
    }
    .level1.close > button,
    .level2.close > button {
        background-image: url('./assets/add.png');
        background-repeat: no-repeat;
        background-size: 15px;
        background-position-y: top;
    }
    .level2,
    .level3 {
        height: 0;
        overflow: hidden;
    }
    .level1.open ul.ul_lvl2 {
        padding-top: 10px;
    }
    .level2.open ul.ul_lvl3 {
        padding-top: 10px;
    }
    .level1.open {
        ul > .level2 {
            height: auto;
        }
    }
    .level2.open {
        ul > .level3 {
            height: auto;
        }
    }
}
