$desktop-min-width: 1180px;
$tablet-min-width: 660px;
$mobile-max-width: 768px;
$mobile-min-width: 250px;
$form-top-separation-desktop: 200px;
$form-top-separation-tablet: 130px;
$form-top-separation-mobile: 80px;
$form-top-separation-mobile-xs: 40px;
$form-max-width-desktop: 500px;
$form-max-width-tablet: 450px;
$form-max-width-mobile: 400px;
$form-max-width-mobile-xs: 350px;

@mixin form-separation-top-desktop() {
  width: 100%;

  @media (min-width: #{$mobile-min-width}) {
    max-width: $form-max-width-mobile-xs !important;
    padding-top: $form-top-separation-mobile-xs !important;
  }

  @media (min-width: #{$mobile-max-width}) {
    max-width: $form-max-width-mobile !important;
    padding-top: $form-top-separation-mobile !important;
  }

  @media (min-width: #{$tablet-min-width}) {
    max-width: $form-max-width-tablet !important;
    padding-top: $form-top-separation-tablet !important;
  }

  @media (min-width: #{$desktop-min-width}) {
    max-width: $form-max-width-desktop !important;
    padding-top: $form-top-separation-desktop !important;
  }
}

@mixin app-d-flex($justify_content) {
  display: flex;
  flex-wrap: wrap !important;
  justify-content: $justify_content;

  &.childs {
    &_100 {
      * {
        display: block !important;
        width: 100%;
      }
    }

    &_uniform {
      * {
        display: block !important;
        flex: 1;
      }
    }
  }
}

@mixin app-flexd($direction) {
  flex-direction: $direction;
}
