@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;

.alert_container {
  background: rgb(0 0 0 / 50%);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__main {
    background-color: #fff;
    border-radius: 4px;
    display: block;
    height: 160px !important;
    margin: auto;
    margin-top: 200px;
    padding: 10px;
    width: 350px;

    &__container {
      height: 100px;
    }

    &__title {
      color: font.$title-color;
      font-size: font.$title-size;
      font-weight: font.$title-weight;
      margin-bottom: 3px;
      position: relative;
    }

    &__title__close {
      color: color.$grey-intermediate;
      cursor: pointer;
      position: absolute;
      right: 0;
    }

    &__text {
      color: font.$subtitle-color;
      font-size: font.$subtitle-size;
      font-weight: font.$title-weight;
      margin-top: 8%;
      text-align: center;
    }
  }

  &__title {
    color: font.$title-color;
    font-size: font.$title-size;
    font-weight: font.$title-weight;
    margin-bottom: 3px;
    position: relative;
  }

  &__title__close {
    color: color.$grey-intermediate;
    cursor: pointer;
    position: absolute;
    right: 0;
  }

  &__text {
    color: font.$subtitle-color;
    font-size: font.$subtitle-size;
    margin-top: 8%;
    text-align: center;
  }
}
