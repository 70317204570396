@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.not-found {
  align-items: center; /* Centrar verticalmente */
  background-color: color.$secondary-color;
  border-radius: 15px;
  display: flex;
  height: 100%;
  width: 100%;

  &__content {
    align-items: flex-start; /* Alinear a la izquierda */
    display: flex;
    flex-direction: column;
    gap: 7%;
    height: 100%; /* Opcional, si quieres que el contenido ocupe toda la altura */
    justify-content: center; /* Centrar verticalmente */
    padding: 10%;
    text-align: left;
    width: 50% !important;

    @media screen and (max-width: mixins.$mobile-max-width) {
      width: 100% !important;
    }

    &__logo {
      width: 50% !important;
    }

    &__title {
      color: color.$white-color;
      font-size: font.$bigtitle-size;
      font-weight: font.$title-weight;
      margin-bottom: 10px; /* Espacio entre el título y el subtítulo */
    }

    &__subtitle {
      color: color.$white-color;
      font-size: font.$title-size;
      font-weight: thin;
      width: 80%;
    }

    &__button {
      margin-top: 5%;
      width: 100%;
    }
  }
}
