@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/variable" as variable;
@use "../../../scss/includes/mixins" as mixins;

.pryma-container {
  align-items: center;
  background-color: color.$secondary-color;
  display: flex;
  height: 100%;
  overflow-x: auto;
  position: relative;

  &__left-section {
    background-color: color.$white-color;
    border-color: color.$black-color;
    border-radius: 15px;
    box-shadow: inset 0 3px 6px #00000029, 0 3px 6px #00000029;
    display: block;
    height: 95%;
    margin: 0 auto;
    margin-left: 1%;
    margin-right: 1%;
    max-width: 330px !important;
    min-height: 400px !important;
    min-width: 180px !important;
    overflow: visible;
    position: relative;
    width: 18.5%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      display: none;
      z-index: 100;
    }

    &__open {
      display: block;
    }

    &__button {
      align-items: center;
      background-color: color.$primary-color;
      border-radius: 15px !important;
      display: flex;
      height: 15%;
      justify-content: center;
      position: absolute;
      right: -10px;
      top: 50%; /* Centra verticalmente el botón */
      transform: translateY(-50%); /* Centra verticalmente el botón */
      width: 8%;
    }
  }

  &__reduce-left-section {
    background-color: color.$primary-color;
    border-color: color.$black-color;
    border-radius: 15px;
    box-shadow: inset 0 3px 6px #00000029, 0 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    height: 95%;
    margin: 0 auto;
    margin-left: 1%;
    margin-right: 1%;
    max-width: 100px !important;
    min-height: 400px !important;
    overflow: visible;
    position: relative; /* Agrega esta propiedad */
    width: 4% !important;

    @media screen and (max-width: mixins.$mobile-max-width) {
      display: none;
      z-index: 100;
    }

    &__header {
      align-items: center;
      align-self: flex-start;
      background-color: color.$white-color;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      display: flex;
      height: 10%;
      justify-content: center;
      width: 100%;
    }

    &__button {
      align-items: center;
      background-color: color.$primary-color;
      border-radius: 15px !important;
      display: flex;
      height: 15%;
      justify-content: center;
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 50%;

      &__lines {
        border-left: 1px solid color.$white-color; /* Borde izquierdo */
        border-right: 1px solid color.$white-color;
        height: 75%;
        margin-left: 12px;
        width: 6px;
        z-index: 100;
      }
    }

    &__logo {
      align-items: center;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      height: 8%;
      margin-top: auto;
      width: 100%;
    }
  }

  &__right-section {
    background-color: color.$white-color;
    border-color: color.$black-color;
    border-radius: 15px;
    box-shadow: inset 0 3px 6px #00000029, 0 3px 6px #00000029;
    display: block;
    height: 95%;
    margin: 0 auto;
    margin-left: 1%;
    margin-right: 1%;
    max-width: 330px !important;
    min-height: 400px !important;
    min-width: 180px !important;
    overflow-y: scroll;
    width: 18.5%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      display: none;
    }

    &__open {
      display: block;
    }
  }

  &__main-content {
    background-color: color.$white-color;
    border-radius: 15px;
    box-shadow: inset 0 3px 6px #00000029, 0 3px 6px #00000029;
    display: block;
    flex: 1;
    height: 95%;
    margin: 0 auto;
    min-height: 400px !important;
    overflow-x: hidden;

    @media screen and (max-width: mixins.$mobile-max-width) {
      border-radius: 0;
      height: 100%;
    }
  }
}

/* @media screen and (max-height: 600px) {
  .pryma-container__left-section {
    overflow: auto;
  }
} */
