@use "../../../../../scss/includes/colors" as color;
@use "../../../../../scss/includes/variable" as variable;
@use "../../../../../scss/includes/fonts" as font;

.calendar-mini {
  align-items: center;
  color: color.$grey-dark;
  height: 100%;
  justify-content: space-between;
  min-height: 300px !important;
  padding: 10px;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 12%;
    justify-content: space-between;
    margin-bottom: 5%;

    &__day {
      color: color.$grey-intermediate;
    }

    &__month {
      font-weight: bold;
      color: color.$grey-dark;
    }
  }

  &__content {
    height: 83%;
    width: 100%;

    &__tableContainer {
      height: 100%;
      width: 100%;

      &__table {
        border-collapse: collapse;
        height: 100%;
        table-layout: fixed; /* Fija el ancho de las columnas */
        width: 100%;

        &__body {
          height: 100%;
          width: 100%;

          &__row {
            &__item {
              &__link {
                align-items: center;
                color: color.$grey-dark;
                display: flex;
                flex-direction: column;
                height: 100% !important;
                justify-content: space-between;
                text-align: center;
                width: 100%;

                &__day {
                  align-items: center;
                  color: color.$grey-dark;
                  display: flex;
                  height: 45% !important;
                  justify-content: center;
                  text-align: center;
                  width: 100%;
                }

                &__dayActual {
                  align-items: center;
                  background-color: color.$primary-color;
                  border-radius: 15px;
                  color: color.$white-color;
                  display: flex;
                  height: 45%;
                  justify-content: center;
                  text-align: center;
                  width: 100%;
                }

                &__events {
                  align-items: center;
                  display: flex;
                  flex-direction: column;
                  height: 50%;
                  text-align: center;
                  width: 100%;

                  &__gestor {
                    background-color: color.$secondary-color;
                    border-radius: 5px;
                    height: 15%;
                    margin-bottom: 5%;
                    width: 80%;

                    &:last-child {
                      margin-bottom: 0 !important;
                    }
                  }

                  &__bags {
                    background-color: color.$glass-color;
                    border-radius: 5px;
                    height: 20%;
                    margin-bottom: 2%;
                    width: 80%;
                  }

                  &__recollection {
                    background-color: color.$primary-color;
                    border-radius: 5px;
                    height: 20%;
                    margin-bottom: 2%;
                    width: 80%;
                  }

                  &__sinader {
                    background-color: color.$plastic-color;
                    border-radius: 5px;
                    height: 20%;
                    margin-bottom: 2%;
                    width: 80%;
                  }

                  &__certificates {
                    background-color: color.$organic-color;
                    border-radius: 5px;
                    height: 20%;
                    margin-bottom: 2%;
                    width: 80%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.day-outside-month {
  opacity: 0.3;
}
