@use "../../../../../scss/includes/colors" as color;
@use "../../../../../scss/includes/fonts" as font;
@use "../../../../../scss/includes/mixins" as mixins;

.calendarRequest {
  align-items: center;
  background: rgb(0 0 0 / 50%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__main {
    background-color: color.$white-color;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1%;
    height: auto !important;
    margin: auto;
    min-width: 450px;
    padding: 15px 20px;
    position: relative; /* Agrega posicionamiento relativo */
    width: 28%;

    &__menu {
      align-items: center;
      color: color.$grey-light;
      display: flex;
      flex-direction: row;
      height: 15px;
      justify-content: space-between;
      justify-content: flex-end;
      width: 100%;
    }

    &__header {
      border-bottom: 0.5px solid color.$grey-light;
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 0 0 3%;

      &__title {
        align-items: center;
        color: color.$grey-dark;
        display: flex;
        flex-direction: row;
        font-weight: bold;
        gap: 3%;
        margin-bottom: 4%;
        width: 100%;

        &__circle {
          background-color: #03dac5;
          border-radius: 50%;
          height: 10px;
          width: 10px;
        }
      }

      &__date {
        color: color.$grey-intermediate;
        display: flex;
        flex-direction: row;
        gap: 3%;
        margin-left: 10px;
        width: 100%;
      }
    }

    &__content {
      border-bottom: 0.5px solid color.$grey-light;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: auto;
      padding: 2% 5%;

      &:last-child {
        border: 0;
      }

      &__item {
        color: color.$grey-dark;
        display: flex;
        flex-direction: column;

        &__title {
          align-items: left;
          color: color.$grey-intermediate;
          display: flex;
          margin-bottom: 1%;
        }

        &__detail {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &__imgContainer {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 15%;

            &__img {
              display: flex;
              height: auto;
              width: 100%;
            }
          }

          &__text {
            display: flex;
            flex-direction: column;
            gap: 3%;
            width: 81%;

            &__name {
              align-items: flex-start;
              display: flex;
              font-weight: bold;
            }

            &__info {
              align-items: flex-start;
              color: color.$grey-intermediate;
              display: flex;
              text-align: left;
            }
          }
        }
      }
    }

    &__form {
      color: color.$grey-dark;
      display: flex;
      flex-direction: column;
      margin-top: 5%;
      text-align: left;
      width: 100%;

      &__label {
        font-weight: bold;
        margin-bottom: 2%;
        margin-top: 4%;
        text-align: left;

        &:first-child {
          margin-top: 0;
        }
      }

      &__select {
        &:hover .ant-select-selector {
          border-color: #2994e6; /* Cambiar color del borde al hacer hover */
        }
      }

      &__date {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__date-picker {
          width: 77%;
        }

        &__hour {
          width: 20%;
        }

        &__recurrency {
          border: 0;
        }
      }

      &__recurrency {
        color: color.$grey-light;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 2%;
        width: 100%;

        &__checkbox {
          align-items: center;
          display: flex;
          flex-direction: row;

          &__label {
            margin-left: 2%;
          }
        }

        &__details {
          align-items: center;
          display: flex; // Cambiar a flex
          justify-content: space-between;
          margin-left: 6%;

          &__weeks {
            width: 15%;
          }

          &__days {
            background-color: color.$grey-background;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            width: 40%;

            &__item {
              border: 1px solid color.$grey-light;
            }
          }
        }
      }

      &__alert {
        color: color.$danger-color;
        margin-top: 4%;
      }

      &__btns {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 4%;
        width: 100%;
      }

      &__material-label-img {
        height: 25px;
        margin-right: 2%;
      }
    }
  }
}

.day-active {
  background-color: color.$grey-light; // Cambia este color según tu diseño
  color: color.$grey-background;
}
