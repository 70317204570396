.loading-wrapper {
  background: rgb(0 0 0 / 70%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  vertical-align: middle;
  width: 100%;
  z-index: 1;

  .spinner-border {
    display: block;
    margin-top: 100px;
    position: relative;
  }
}

.loader {
  animation: rotation 1s linear infinite;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 60px;
  width: 60px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
