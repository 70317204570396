@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.monthlyevolution-card {
  display: relative;
  height: 25%;
  margin-right: 0%;
  min-height: 280px;
  text-align: left;

  @media screen and (max-width: mixins.$mobile-max-width) {
    max-height: 300px;
  }

  &__titulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    display: block;
    font-size: font.$title-size;
    font-weight: font.$title-weight;
    height: 20px;

    /*  @media screen and (max-width: 400px) {
      font-size: font.$mobile-title-size;
    } */
  }

  &__subtitulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    display: block;
    font-size: font.$subtitle-size;
    font-weight: font.$title-weight;
    height: 27px;

    &__icon {
      margin-left: 1%;
    }

    /* @media screen and (max-width: 400px) {
      font-size: font.$mobile-subtitle-size;
    } */
  }

  &__content {
    background-color: color.$grey-background;
    border-radius: 15px !important;
    display: block;
    height: 85%;
    padding: 3%;
    position: relative;

    &__detail {
      color: color.$grey-light;
    }

    &__chart {
      height: 100%;
      width: 100%;

      @media screen and (max-width: mixins.$mobile-max-width) {
        height: 230px !important;
        margin-top: -15px;
      }
    }

    &__chart canvas {
      height: 100% !important;
      width: 100%;
    }
  }
}

.charts {
  margin-top: 15%;
}

.chart-container {
  height: 100%;
  width: 100%;
}
