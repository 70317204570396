@use "../../../../scss/includes/colors" as color;
@use "../../../../scss/includes/fonts" as font;

.wastecard {
  border-radius: 15px;
  position: relative;

  &__header {
    background-color: color.$white-color;
    height: 20%;
    width: 100%;
  }

  &__body {
    background-color: color.$grey-background;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 30%;
    width: 100%;

    &__logo {
      height: 50%;
      left: 30%;
      position: absolute;
      top: -2% !important;
      transform: translateX(-50%) !important;
    }

    &__past-month {
      margin-top: 10%;
      overflow: hidden;
      position: absolute;
      right: 5%;

      &__text {
        color: color.$grey-dark;
        cursor: default;
        display: block;
        font-size: font.$subsubtitle-size;
        justify-content: right !important;
        text-decoration: none;
      }
    }
  }

  &__content {
    background-color: color.$grey-background;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 60%;
    position: relative;
    width: 100%;

    &__kg {
      color: color.$grey-dark;
      font-size: font.$bigtitle-size;
      font-weight: bold;
      margin-left: 10%;
    }

    &__name {
      color: color.$black-color;
      font-size: font.$subsubtitle-size;
      font-weight: bold;
      margin-left: 10%;
    }

    &__ver {
      bottom: 20%;
      color: color.$grey-intermediate;
      font-size: font.$subsubtitle-size;
      margin-left: 10%;
      position: absolute;
    }
  }
}
